import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

function Button({
  text,
  icons,
  type = "primary",
  href = "/",
  className = "",
  ...props
}) {
  if (type === "primary") {
    return (
      <Link
        to={href}
        className={cn([
          `text-white 
           border-2
           flex items-center justify-center
           md:px-5 md:py-2
           px-3 py-2
           rounded-lg
           font-bold
           duration-75
           bg-primary  border-primary  hover:bg-transparent
          hover:border-white  text-base`,
          className,
        ])}
        {...props}
      >
        {text}
        <div className="pl-3"> {icons}</div>
      </Link>
    );
  } else if (type === "outline-primary") {
    return (
      <Link
        to={href}
        className={cn([
          `text-primary text-center
           border-2
                 flex items-center justify-center
           md:px-5 md:py-2
           px-3 py-2
           rounded-lg
           font-bold
           duration-75
           bg-transparent border-primary hover:bg-primary
          hover:text-white `,
          className,
        ])}
        {...props}
      >
        {text}
      </Link>
    );
  } else if (type === "outline-white") {
    return (
      <Link
        to={href}
        className={cn([
          `text-white text-center
           border-2
           flex items-center justify-center
           md:px-5 md:py-2
           px-3 py-2
           rounded-lg
           font-bold
           duration-75
           bg-transparent 
           border-white hover:bg-primary
          hover:border-primary  text-base`,
          className,
        ])}
        {...props}
      >
        {text}
      </Link>
    );
  } else if (type === "bg-main") {
    return (
      <Link
        to={href}
        className={cn([
          `text-white 
                    text-center
                    border-2
                   flex items-center justify-center
                    md:px-5 md:py-2
                    px-3 py-2
                   rounded-lg
                    font-bold
                  duration-75
                   bg-main border-main hover:bg-primary
          hover:text-white hover:border-primary`,
          className,
        ])}
        {...props}
      >
        {text}
      </Link>
    );
  }
}

export default Button;
