import React, { useState } from "react";
import CModal from "react-modal";
import { FaTimes } from "react-icons/fa";

CModal.setAppElement("#root");

function Modal({ show = false, setShow, size = "md", header = "", children }) {
  function closeModal() {
    setShow(false);
  }

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "9999",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: size === "md" ? "50%" : "80%",
      padding: "0",
    },
  };

  return (
    <CModal isOpen={show} onRequestClose={closeModal} style={customStyles}>
      <div className="p-2">
        <div className="flex  items-start justify-between border-b-2 border-gray-300 py-2 ">
          <h5 className="font-bold text-2xl">{header}</h5>
          <button
            className="text-gray-600 hover:text-gray-800   "
            onClick={closeModal}
          >
            <FaTimes />
          </button>
        </div>

        <div
          className="py-3  "
          style={{
            overflowY: "scroll",
            maxHeight: "90vh",
          }}
        >
          {children}
        </div>
      </div>
    </CModal>
  );
}

export default Modal;
