import React from "react";

function AboutSection({ image, title, text }) {
  return (
    <div className=" items-center flex flex-col md:w-1/3 md:px-5">
      <div className="mb-5 ">
        <img height="50px" src={image} alt="" />
      </div>
      <h3 className="mb-5 font-bold text-xl ">{title}</h3>
      <p className="text-center mb-16">{text}</p>
    </div>
  );
}

export default AboutSection;
