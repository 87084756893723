import React, { useState } from "react";
import Layout from "../components/partials/layout";
import PageHeader from "../components/elements/page-header";
import { useTranslation } from "react-i18next";

export default function CookiePolicy() {
  const { t } = useTranslation();

  return (
    <Layout>
      <PageHeader
        page={t("cookies_title")}
        home={t("global_header_home")}
        text={"-" + t("cookies_title")}
        image="doctors-image"
      />
      <div className="md:container md:mx-auto px-5">
        <h1 className="font-bold text-main text-xl mb-4">
          {t("cookies_title")}
        </h1>
        <p className="mb-4"></p>
        <h1>Çerez Politikası</h1>

        <p>
          NDK PSİKOLOJİK DANIŞMANLIK MERKEZİ ("NDK Danışmanlık") olarak internet
          sitemizde çerez kullanmaktayız. Bu Çerez Politikası ("Politika") NDK
          Danışmanlık tarafından yönetilen{" "}
          <a href="https://ndkdanismanlik.com/">https://ndkdanismanlik.com/</a>{" "}
          adresli internet sitesi için geçerli olup çerezler işbu Politika'da
          belirtilen şekilde kullanılacaktır.
        </p>

        <h2>Çerez Nedir?</h2>
        <p>
          Çerezler bir internet sitesini ziyaret edildiğinde internet sitesi
          tarafından tarayıcınız aracılığıyla cihazına veya ağ sunucusuna
          depolanan küçük metin dosyalarıdır.
        </p>

        <h2>Çerezler Hangi Amaçla Kullanılmaktadır?</h2>
        <p>
          Çerezler genellikle site kullanıcısının deneyim kalitesini arttırmak
          için kullanılır. Çerezler sayesinde ziyaret edilen internet sitesi
          tekrar ziyaret edildiğinde ziyaretçinin önceki tercihlerini
          hatırlayabilir. Böylece internet sitesinin işlevselliğini arttırarak
          sunulan hizmeti geliştirir. Çerezler aynı zamanda internet sitesi
          üzerinden yeni özellikler sunmak ve sunulan özellikleri iyileştirmek
          için kullanılmaktadır.
        </p>

        <h2>
          Sitemizde Kullanılan Çerezler Nelerdir ve Ne Süreyle Kullanılırlar?
        </h2>
        <ol>
          <li>
            <strong>Teknik Çerezler</strong>
            <br />
            Teknik çerezler sitenin olması gerektiği gibi çalışmasını
            sağlamaktadır. Sitenin çalışmayan sayfaları ve alanları bu
            çerezlerce tespit edilir ve onarımı için aksiyon alınır.
            <br />
            Bu çerezin kullanım süresi: 6 ay.
          </li>
          <li>
            <strong>Otantikasyon Çerezleri</strong>
            <br />
            Ziyaretçilerin siteye şifrelerini kullanarak giriş yapmaları
            durumunda otantikasyon çerezleri site içinde ziyaret devam ettikçe
            tekrar şifre sorulmamasını sağlar.
            <br />
            Bu çerezin kullanım süresi: 3 ay.
          </li>
          <li>
            <strong>Analitik Çerezler</strong>
            <br />
            Analitik çerezler siteyi ziyaret edenlerin sayılarının belirlenmesi,
            hangi sayfaların ziyaret edildiğinin tespiti, sitenin ziyaret
            edildiği saatler gibi verilerle ilgili sonuç üretimini sağlayan
            çerezlerdir.
            <br />
            Bu çerezin kullanım süresi: 1 yıl.
          </li>
          <li>
            <strong>Üçüncü Taraf Çerezleri</strong>
            <br />
            Üçüncü taraf çerezleri diğer siteler tarafından oluşturulur ve
            ziyaretçilerin gördüğü reklam veya resim gibi içeriklerin bir
            kısmına sahiptir.
            <br />
            Bu çerezin kullanım süresi: 2 gün.
          </li>
        </ol>

        <p>
          Çerezler işbu Politika'da belirtilen amaçlar dışında kullanılmamakta
          olup tüm ilgili işlemler veri koruma mevzuatına uygun olarak
          yürütülmektedir.
        </p>

        <h2>Toplanan Veriler Nelerdir?</h2>
        <p>
          Yukarıda adı geçen çerezlerle toplanan veriler şunlardır:
          <br />
          Web sitemizde, şüpheli eylemlerin tespiti yoluyla güvenliğin
          sağlanması, kullanıcıların tercihleri doğrultusunda işlevsellik ve
          performansın artırılması, ürünlerin ve hizmetlerin geliştirilmesi ve
          kişiselleştirilmesi ile bu hizmetlere ulaşımın kolaylaştırılması
          amaçlı çerezler kullanmaktadır. Ayrıca kullanıcıların daha geniş
          kapsamlı hizmet sağlayıcılar ile buluşturulabilmesi amacıyla reklam
          çerezleri ve üçüncü taraflarla bilgi paylaşımı da söz konusudur.
        </p>
        <h2>Gizlilik Politikamız</h2>

        <p>
          Topladığımız verilerle ilgili internet sitemizin Gizlilik Politikasına
          şu adresten ulaşabilirsiniz:{" "}
          <a href="https://ndkdanismanlik.com/kvkk">
            https://ndkdanismanlik.com/kvkk
          </a>
          .
        </p>

        <h2>Çerezleri Nasıl Kontrol Edebilirsiniz?</h2>

        <p>
          Tarayıcılar genellikle internet sitemize ilk girişinizde çerezleri
          kabul edip etmediğinizi, ediyorsanız hangi çerezleri kabul etmek
          istediğinizi sormaktadırlar. Bu şekilde ziyaretçiler çerezleri kontrol
          edebilirler. İnternet sitemizi kullanmak için çerez kullanımı zorunlu
          değildir. Bu bağlamda çerezlerden bazılarını veya tamamını kabul
          etmemeniz halinde kullanıcı deneyiminizin kalitesi düşebilir veya
          sitede çeşitli işlev bozuklukları meydana gelebilir. Bu bozukluklardan
          şirketimiz sorumlu değildir. İnternet sitesini görüntülemek için
          farklı araçlar (ör. bilgisayar, cep telefonu ya da tablet)
          kullanıyorsanız, bu cihazların her birinde kullandığınız tarayıcının
          çerez tercihlerinize uygun olduğundan emin olun.
        </p>

        <p>Bu Politika en son 26.06.2023 tarihinde güncellenmiştir.</p>

        <br />
      </div>
    </Layout>
  );
}
