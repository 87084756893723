import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";

function Layout({ children, type = false , pageTitle}) {
  useEffect(() => window.scroll(0, 0), []);

  const currentUrl = window.location.href;

  return (
    <>
      <Helmet>
          <title>{pageTitle} - NDK Danışmanlık</title>
          <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Header type={type} />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
