import React from "react";

export default function ContactCard({ icon, text, number }) {
  return (
    <div className="md:w-1/2 w-full ">
      <div className="p-3 border-2  rounded-xl p-4 m-2">
        <div className="text-primary text-3xl mb-2">{icon}</div>
        <div className="mb-2">{text}</div>
        <div className="text-primary text-lg font-bold">{number}</div>
      </div>
    </div>
  );
}
