import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";

function PageHeader({ image, text, page, home }) {
  return (
    <div className="md:mb-16 mb-5">
      <div className={cn(["", image])}>
        <div className="h-full flex flex-col items-center justify-center text-white text-center ">
          <h1 className="text-3xl font-bold mb-2">{page} </h1>
          <div className="text-sm flex">
            <NavLink to="/" className="">
              {home}
            </NavLink>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
