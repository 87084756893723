import React, { useState, useEffect } from "react";
import "./css/credit-card.css";
import "./css/form-style.css";
import Cards from "react-credit-cards";
import { toast } from "react-toastify";

const Creditcard = ({ paymentSubmit, header = "", price, priceId }) => {
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [expiryyear, setExpiryyear] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  let timeoutID = null;

  useEffect(() => {
    //if any of the events fire, it resets the timer
    window.addEventListener("keypress", () => {
      resetTimer();
    });
    window.addEventListener("keyup", () => {
      resetTimer();
    });
    window.addEventListener("scroll", () => {
      resetTimer();
    });
    window.addEventListener("keydown", () => {
      resetTimer();
    });
    window.addEventListener("mousemove", () => {
      resetTimer();
    });
    window.addEventListener("mousewheel", () => {
      resetTimer();
    });
    window.addEventListener("mousedown", () => {
      resetTimer();
    });
    window.addEventListener("touchmove", () => {
      resetTimer();
    });
    window.addEventListener("MSPointerMove", () => {
      resetTimer();
    });
    window.addEventListener("DOMMouseScroll", () => {
      resetTimer();
    });
    window.addEventListener(onscroll, () => {
      resetTimer();
    });
    startTimer();

    setInterval(() => {
      var hours = 0.5;
      var now = new Date().getTime();
      var setupTime = sessionStorage.getItem("setupTime");

      if (setupTime === null) {
        sessionStorage.setItem("setupTime", now);
      } else {
        if (now - setupTime > hours * 60 * 60 * 1000) {
          sessionStorage.clear();
          sessionStorage.setItem("setupTime", now);
        }
      }
    }, 1000);
  }, []);

  const goInactive = () => {
    alert("Oturumunuzun süresi doldu.Lütfen sayfayı yenileyin.");

    sessionStorage.clear();
  };

  const goActive = () => {
    startTimer();
  };
  const resetTimer = () => {
    window.clearTimeout(timeoutID);
    goActive();
  };
  const startTimer = () => {
    timeoutID = window.setTimeout(goInactive, 60000 * 30);
  };

  /*

   */
  const removeSpecial = (e) => {
    var invalidChars = ["-", "+", "e", "E", " ", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const addSpace = (e) => {
    const { value, id } = e.target;
    var ele = document.getElementById(id);
    if (value.length === 4 || value.length === 9 || value.length === 14)
      ele.value = ele.value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
  };

  const validateInput = (e, setState) => {
    const { name, value, maxLength, id } = e.target;

    var temp, ele;

    if (id === "cvv") {
      if (value.length > maxLength) {
        temp = value.slice(0, maxLength);
        const num = temp;
        ele = document.getElementById(id);
        ele.value = temp;
        setState(num);
      } else {
        setState(value);
      }
    } else {
      ele = document.getElementById(id);

      ele.value = ele.value.replace(
        /[A-Za-z}"`~_=.\->\]|<?+*/,;\[:{\\!@#\/'$%^&*()]/g,
        ""
      );
      setState(ele.value);
    }
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e, setState) => {
    const { name, value, id } = e.target;

    if (id === "cardHolder") {
      var ele = document.getElementById(id);

      ele.value = ele.value.replace(
        /[}"`~_=.\->\]|<?+*/,\d;\[:{\\!@#\/'$%^&*()]/g,
        ""
      );
      setState(ele.value);
    } else setState(value);
  };

  const onSubmit = (e) => {
    try {
      e.preventDefault();

      let sendStatus = true;

      if (number.length < 16) {
        toast.error("Kart numarası 16 haneli olmalıdır.");
        sendStatus = false;
        return;
      }

      if (expiry.trim().length === 0) {
        toast.error("Son kullanma ayı giriniz.");
        sendStatus = false;
        return;
      }

      if (expiryyear.trim().length === 0) {
        toast.error("Son kullanma yılı giriniz.");
        sendStatus = false;
        return;
      }

      if (cvc.trim().length === 0) {
        toast.error("CVV giriniz.");
        sendStatus = false;
        return;
      }

      if (name.trim().length === 0) {
        toast.error("Kart sahibi giriniz.");
        sendStatus = false;
        return;
      }

      if (sendStatus) {
        const names = name.split(" ");

        const firstName =
          names.length > 1 ? names.slice(0, names.length - 1).join(" ") : "";

        const lastName = names.length > 1 ? names[names.length - 1] : "";

        paymentSubmit(
          number,
          expiry,
          expiryyear,
          cvc,
          firstName,
          lastName,
          priceId
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="credit-card ">
        <Cards
          locale={{ valid: "S.K." }}
          placeholders={{ name: "AD SOYAD" }}
          cvc={cvc}
          expiry={expiry}
          expiryyear={expiryyear}
          focused={focus}
          name={name}
          number={number}
        />
      </div>
      <div className="card">
        <form className="payment-form" onSubmit={onSubmit}>
          <h3 className="font-bold text-gray-700 text-3xl mb-2">{header}</h3>
          <div className="form-group">
            <label htmlFor="cardNumber" className="card-label">
              Kart Numarası
            </label>
            <input
              type="text"
              onChange={(e) => validateInput(e, setNumber)}
              value={number}
              onKeyDown={removeSpecial}
              onPaste={(e) => e.preventDefault()}
              onKeyPress={addSpace}
              onFocus={(e) => setFocus(e.target.name)}
              name="number"
              maxLength="19"
              id="cardNumber"
              className="payment-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="cardHolder" className="card-label">
              Ad ve Soyad
            </label>
            <input
              type="text"
              name="name"
              spellCheck="false"
              value={name}
              maxLength="20"
              autoComplete="off"
              onPaste={(e) => e.preventDefault()}
              onChange={(e) => handleInputChange(e, setName)}
              onFocus={(e) => setFocus(e.target.name)}
              id="cardHolder"
              className="payment-input"
            />
          </div>
          <div className="date-cvv-box">
            <div className="expiry-class">
              <div className="form-group card-month ">
                <label htmlFor="cardMonth" className="card-label">
                  S.K. Tarihi
                </label>

                <select
                  id="cardMonth"
                  data-ref="cardDate"
                  value={expiry}
                  name="expiry"
                  onChange={(e) => handleInputChange(e, setExpiry)}
                  onFocus={(e) => setFocus(e.target.name)}
                  className="payment-input"
                >
                  <option value="" defaultChecked="true">
                    Ay
                  </option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                    (month, index) => (
                      <option key={index} value={month}>
                        {month < 10 ? `0${month}` : month}
                      </option>
                    )
                  )}
                  <option value="01">01</option>
                </select>
              </div>
              <div className="form-group card-year">
                <select
                  id="cardYear"
                  data-ref="cardDate"
                  value={expiryyear}
                  name="expiryyear"
                  onChange={(e) => handleInputChange(e, setExpiryyear)}
                  onFocus={(e) => setFocus(e.target.name)}
                  className="payment-input   "
                >
                  <option value="" defaultChecked="true">
                    Yıl
                  </option>

                  {[
                    new Date().getFullYear(),
                    new Date().getFullYear() + 1,
                    new Date().getFullYear() + 2,
                    new Date().getFullYear() + 3,
                    new Date().getFullYear() + 4,
                    new Date().getFullYear() + 5,
                    new Date().getFullYear() + 6,
                    new Date().getFullYear() + 7,
                    new Date().getFullYear() + 8,
                    new Date().getFullYear() + 9,
                    new Date().getFullYear() + 10,
                  ].map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="cvv-class form-group">
              <label htmlFor="cvv" className="card-label cvv-label  ">
                CVV
              </label>
              <input
                type="number"
                onChange={(e) => validateInput(e, setCvc)}
                onKeyDown={removeSpecial}
                onPaste={(e) => e.preventDefault()}
                onFocus={(e) => setFocus(e.target.name)}
                name="cvc"
                id="cvv"
                value={cvc}
                className="payment-input"
                maxLength="4"
              />
            </div>
          </div>

          <button className="text-white border-2 w-full flex items-center justify-center py-2  rounded-lg font-bold duration-75 border-main  bg-main  hover:bg-white hover:text-main text-base">
            Ödemeyi Tamamla {price} ₺
          </button>
        </form>
      </div>
    </div>
  );
};

export default Creditcard;
