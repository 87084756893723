import React, { useState } from "react";
import axios from 'axios'

import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaMapPin,
  BiCheckbox,
} from "react-icons/fa";
import { MdCheckBox } from 'react-icons/md'

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ContactForm() {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const dataControl = (array) => {
    let sendDatas = true;
    array.map((item) => { //array.forEach
      if (item.input.value.trim().length === 0) {
        toast(item.text);
        item.input.focus();
        sendDatas = false;
      }
    });

    return sendDatas;
  }

  const handleSubmit = async(e) => {
    e.preventDefault();

    const { name, phone, email, message, terms } = e.target;


    const datas = [
      {
        input: name,
        text: "Ad ve Soyad Giriniz!",
      },
      {
        input: email,
        text: "E-Posta Giriniz!",
      },
      {
        input: phone,
        text: "Telefon Giriniz!",
      },

      {
        input: message,
        text: "Mesaj Giriniz!",
      },
    ];

    const data = {
      name_surname: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value
    };

    if (checked) {
      if (dataControl(datas)) {
        await axios
          .post(process.env.REACT_APP_API_URL + "contact-forms", data)
          .then((res) => {
            if (res.data && res.data.status) {
              toast.success("Mesajınız başarıyla gönderildi");
	      setTimeout(() => {
	          window.location.reload();
	      }, 1000);
            }
          })
          .catch((err) => {
            toast.error("Mesajınız iletilemedi");
            console.log(err);
          });

      }
    } else {
      toast("Lütfen KVK Aydınlatma Metni'ni Onaylayınız");
      terms.focus();
    }
  };

  return (
    <form className="w-full " onSubmit={handleSubmit}>
      <div className="flex flex-wrap -mx-3 mb-4 px-3 rounded-xl">
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-first-name"
        >
          {t("contact_form_name")}{" "}
          <span className="text-red-600 text-lg">*</span>
        </label>
        <input
          className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3
                    leading-tight focus:outline-none focus:border-primary"
          id="grid-first-name"
          type="text"
          placeholder={t("contact_form_name")}
          name="name"
          required
        />
      </div>

      <div className="flex flex-wrap -mx-3 rounded-xl">
        <div className="w-full mb-4 px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            {t("contact_form_email")}
            <span className="text-red-600 text-lg">*</span>
          </label>
          <input
            className="appearance-none block w-full
                       text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight
                        focus:outline-none  focus:border-primary"
            id="email"
            type="email"
            placeholder={t("contact_form_email")}
            name="email"
            required
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 rounded-xl">
        <div className="w-full px-3 mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            {t("contact_form_phone")}{" "}
            <span className="text-red-600 text-lg">*</span>
          </label>
          <input
            className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none
                      focus:border-primary"
            id="phone"
            type="phone"
            name="phone"
            placeholder={t("contact_form_phone")}
            required
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 p-2 text-main">
        <div className="w-full mb-4 px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            {t("contact_form_message")}{" "}
            <span className="text-red-600 text-lg">*</span>
          </label>
          <textarea
            className=" no-resize appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-primary h-48 resize-none"
            id="message"
            name="message"
            placeholder={t("contact_form_message")}
            required
          />
        </div>
      </div>

      <div className="md:flex  pl-2 items-center">
        <div className="w-6/12 mb-2">
          <button
            className="shadow bg-primary hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
          >
            {t("global_send")}
          </button>
        </div>

        <div className="w-8/12 flex items-center">
          <input
            type="checkbox"
            name="terms"
            checked={checked}
            onChange={() => setChecked(!checked)}
            className="mr-2  "
            id="checkbox"
            required
          />
          <label
            dangerouslySetInnerHTML={{
              __html: t("global_kvkk_text"),
            }}
            className="text-sm text-main "
            htmlFor="checkbox"
          />
        </div>
      </div>
    </form>
  );
}
