import React from "react";
import Button from "../base/button";
import AboutImage from "../../images/about-image.jpg";
import { useTranslation } from "react-i18next";

function AboutUsSection({ type = false }) {
  const { t } = useTranslation();
  return (
    <div className="md:flex md:container md:mx-auto md:pt-20 p-5 justify-around items-start ">
      <div className="md:mr-10 mt-10  flex ">
        <img width="500px" src={AboutImage} alt="" />
      </div>
      <div className="md:w-1/2 pt-8 md:mt-10 ">
        <h2 className="font-bold sm:text-base text-sm mb-2  ">
          {t("global_about_us_section_little_title")}
        </h2>
        <h2
          className="font-bold  text-main md:text-3xl text-2xl mb-8 about-line "
          dangerouslySetInnerHTML={{
            __html: t("global_about_us_section_title"),
          }}
        />

        <p className="text-main text-base md:text-lg mr-12 md:mb-24 mb-5  ">
          {t("global_about_us_section_text")}
        </p>

        <div className="flex justify-between ">
          {type ? (
            <>
              <Button
                text={t("global_make_appointment")}
                href="/randevu-al"
                className="hover:bg-main w-5/12 md:w-2/6 "
              />
              <Button
                text={t("global_header_contact")}
                href="/iletisim"
                type="outline-primary"
                className=" md:w-2/6 w-5/12  md:mr-12"
              />
            </>
          ) : (
            <Button
              text={t("global_header_about")}
              href="/hakkimizda"
              className="hover:bg-main  w-2/6 hover:border-2 hover:border-main"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutUsSection;
