import React, { useState } from "react";
import Layout from "../components/partials/layout";
import PageHeader from "../components/elements/page-header";
import { useTranslation } from "react-i18next";
export default function KvkkView() {
  const { t } = useTranslation();
  return (
    <Layout pageTitle={t("kvkk_title")}>
      <PageHeader
        page={t("footer_kvkk")}
        home={t("global_header_home")}
        text={"-" + t("footer_kvkk")}
        image="doctors-image"
      />
      <div className="md:container md:mx-auto px-5">
        <h1 className="font-bold text-main text-xl mb-4">{t("kvkk_title")}</h1>
        <p className="mb-4">
          <p>&nbsp;</p>
          <p>
            NDK Danışmanlık (&ldquo;NDK Danışmanlık&rdquo;) olarak, 6698 sayılı
            Kişisel Verilerin Korunması Kanunu (&ldquo;KVKK&rdquo;) madde 10
            uyarınca ve Kişisel Verileri Koruma Kurumu tarafından yayımlanan
            Aydınlatma Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n Yerine
            Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ&rsquo;e
            uygun olarak, kişisel verilerin işlenmesine ilişkin aydınlatma
            y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirmek amacıyla
            bu Aydınlatma Metnini ilgili kişilerin bilgisine sunmaktayız.
          </p>
          <p>
            Tanımlar A&ccedil;ık Rıza: Belirli bir konuya ilişkin,
            bilgilendirilmeye dayanan ve &ouml;zg&uuml;r iradeyle
            a&ccedil;ıklanan olumlu onay beyanı.
          </p>
          <p>
            Kişisel Veri: Kimliği belirli veya belirlenebilir ger&ccedil;ek
            kişiye ilişkin her t&uuml;rl&uuml; bilgi.
          </p>
          <p>
            &Ouml;zel Nitelikli Kişisel Veri: Kişilerin ırkı, etnik k&ouml;keni,
            siyasi d&uuml;ş&uuml;ncesi, felsefi inancı, dini, mezhebi veya diğer
            inan&ccedil;ları, kılık ve kıyafeti, dernek, vakıf ya da sendika
            &uuml;yeliği, sağlığı, cinsel hayatı, ceza mahk&ucirc;miyeti ve
            g&uuml;venlik tedbirleriyle ilgili verileri ile biyometrik ve
            genetik verileri.
          </p>
          <p>
            Kişisel Verilerin İşlenmesi: Kişisel verilerin tamamen veya kısmen
            otomatik olan ya da herhangi bir veri kayıt sisteminin
            par&ccedil;ası olmak kaydıyla otomatik olmayan yollarla elde
            edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
            değiştirilmesi, yeniden d&uuml;zenlenmesi, a&ccedil;ıklanması,
            aktarılması, devralınması, elde edilebilir h&acirc;le getirilmesi,
            sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler
            &uuml;zerinde ger&ccedil;ekleştirilen her t&uuml;rl&uuml; işlem.
          </p>
          <p>
            Veri Sorumlusu: Kişisel verilerin işleme ama&ccedil;larını ve
            vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
            y&ouml;netilmesinden sorumlu olan ger&ccedil;ek veya t&uuml;zel
            kişi.
          </p>
          <p>
            Veri Kayıt Sistemi: Kişisel verilerin belirli kriterlere g&ouml;re
            yapılandırılarak işlendiği kayıt sistemi.
          </p>
          <p>İlgili Kişi: Kişisel verisi işlenen ger&ccedil;ek kişi.</p>
          <p>
            Kişisel Verilerin İşleme Ama&ccedil;ları NDK Danışmanlık, kişisel
            verilerinizi aşağıdaki ama&ccedil;larla toplar ve işler.
          </p>
          <p>
            &Uuml;r&uuml;n ve hizmetlerimizi geliştirmek, sağlamak ve
            iyileştirmek, &Uuml;r&uuml;n ve hizmetlerimizi tanıtmak ve
            pazarlamak, Ticari faaliyetimizi eksiksiz s&uuml;rd&uuml;rmek Bu
            temel ama&ccedil;larla ilgili olarak;
          </p>
          <p>
            Abone portf&ouml;y&uuml;m&uuml;z&uuml; y&ouml;netmek, Her
            t&uuml;rl&uuml; ara&ccedil;la iletişim kurmak, Reklam, tanıtım ve
            bilgilendirme faaliyetlerinde bulunmak, Web sitesi
            ziyaret&ccedil;ilerimizi analiz etmek ve arşivlemek,
            Uygulama/aplikasyon geliştirmek ve y&ouml;netmek, Veri kayıplarının
            &ouml;nlenebilmesi i&ccedil;in kopyalama/yedekleme yapmak, Yasal
            d&uuml;zenlemelerin gerektirdiği veya zorunlu kıldığı şekilde yasal
            y&uuml;k&uuml;ml&uuml;l&uuml;klerin, hakların ve sorumlulukların
            yerine getirilmesini sağlamak, Adli ve idari mercilerce talep edilen
            veri saklama, raporlama ve bilgilendirme
            y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getirmek, KVKK'da
            belirtilen diğer ama&ccedil;lar
          </p>
          <p>Kişisel Verilerin Aktarılacağı Alıcılar:</p>
          <p>
            ndkdanismanlik.com, belirtilen temel ama&ccedil;lar doğrultusunda
            toplanan kişisel verileri aşağıdaki alıcılara aktarabilir:
          </p>
          <p>
            Bağlı şirketler: ndkdanismanlik.com, &uuml;r&uuml;n ve hizmetlerin
            geliştirilmesi, sağlanması veya pazarlanması i&ccedil;in bağlı
            şirketleriyle kişisel veri paylaşımı yapabilir. Bu bağlı şirketler,
            ndkdanismanlik.com'un hizmetlerine destek sağlayan, reklam ve
            pazarlama faaliyetleri y&uuml;r&uuml;ten veya teknik altyapı
            hizmetleri sunan şirketler olabilir.
          </p>
          <p>
            &Ccedil;&ouml;z&uuml;m ortakları: ndkdanismanlik.com, işbirliği
            yaptığı &ccedil;&ouml;z&uuml;m ortaklarıyla kişisel veri paylaşımı
            yapabilir. Bu &ccedil;&ouml;z&uuml;m ortakları,
            ndkdanismanlik.com'un hizmetlerinin sunumunda destek sağlayan veya
            m&uuml;şterilere ek &uuml;r&uuml;n ve hizmetler sunan şirketler
            olabilir.
          </p>
          <p>
            Kanuni y&uuml;k&uuml;ml&uuml;l&uuml;kler: ndkdanismanlik.com, yasal
            d&uuml;zenlemelerin gerektirdiği durumlarda veya yasal
            y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi amacıyla
            kişisel verileri resmi mercilere veya kurumlara aktarabilir.
          </p>
          <p>
            Diğer alıcılar: ndkdanismanlik.com, KVKK kapsamında a&ccedil;ık rıza
            alınması gerekmeyen durumlar dışında, kişisel verileri yasal
            sınırlamalar &ccedil;er&ccedil;evesinde ve gizlilik politikasına
            uygun şekilde diğer alıcılara aktarabilir.
          </p>
          <p>
            Kişisel Veri Toplama Y&ouml;ntemleri ve Hukuki Sebepleri:
            ndkdanismanlik.com, kişisel verileri aşağıdaki y&ouml;ntemlerle
            toplayabilir:
          </p>
          <p>
            İnternet sitesi ve uygulamalar aracılığıyla: ndkdanismanlik.com,
            internet sitesi veya uygulamalar &uuml;zerinden kullanıcıların
            sağladığı kişisel verileri toplayabilir.
          </p>
          <p>
            İletişim ara&ccedil;larıyla: ndkdanismanlik.com, iletişim yoluyla
            kullanıcılardan kişisel verileri toplayabilir.
          </p>
          <p>
            Diğer y&ouml;ntemler: ndkdanismanlik.com, KVKK'ya uygun olarak diğer
            hukuki y&ouml;ntemlerle kişisel veri toplayabilir.
          </p>
          <p>
            Kişisel verilerinizin toplanması ve işlenmesi i&ccedil;in yasal
            dayanağımız, KVKK'nın 5. ve 6. maddelerinde belirtilen hallerdir:
          </p>
          <p>İlgili kişinin a&ccedil;ık rızası</p>
          <p>Kanunda a&ccedil;ık&ccedil;a &ouml;ng&ouml;r&uuml;lmesi</p>
          <p>
            Fiili imk&acirc;nsızlık nedeniyle rızanın a&ccedil;ık&ccedil;a beyan
            edilememesi veya hukuki ge&ccedil;erlilik kazanmaması durumu
            hari&ccedil;, ilgili kişinin veya başka bir kişinin hayatı veya
            beden b&uuml;t&uuml;nl&uuml;ğ&uuml;n&uuml;n korunması i&ccedil;in
            zorunlu olması
          </p>
          <p>
            Bir s&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
            olması kaydıyla, s&ouml;zleşmenin taraflarına ait kişisel verilerin
            işlenmesinin gerekli olması
          </p>
          <p>
            Veri sorumlusunun hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;
            yerine getirebilmesi i&ccedil;in zorunlu olması
          </p>
          <p>İlgili kişinin kendisi tarafından alenileştirilmiş olması</p>
          <p>
            Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in veri
            işlemenin zorunlu olması
          </p>
          <p>
            Veri sorumlusunun meşru menfaatleri i&ccedil;in veri işlenmesinin
            zorunlu olması, ilgili kişinin temel hak ve
            &ouml;zg&uuml;rl&uuml;klerine zarar vermemek kaydıyla
          </p>
          <p>
            &Ouml;zel nitelikli kişisel veriler, yukarıda belirtilen haller
            dışında ilgili kişinin a&ccedil;ık rızasına dayanarak veya KVKK'nın
            &ouml;ng&ouml;rd&uuml;ğ&uuml; hallerde işlenebilir.
          </p>
          <p>
            İlgili Kişinin Hakları: KVKK kapsamında, kişisel verileri işlenen
            ger&ccedil;ek kişilerin belirli hakları bulunmaktadır. İlgili kişi,
            başvuru hakkını kullanarak aşağıdaki taleplerde bulunabilir:
          </p>
          <p>Kişisel verilerinin işlenip işlenmediğini &ouml;ğrenme</p>
          <p>Kişisel verileri işlenmişse buna ilişkin bilgi alma</p>
          <p>
            Kişisel verilerin hangi ama&ccedil;la işlendiğini ve bu
            ama&ccedil;lara uygun şekilde kullanılıp kullanılmadığını
            &ouml;ğrenme
          </p>
          <p>
            Kişisel verilerin yurt i&ccedil;inde veya yurt dışında aktarıldığı
            &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme
          </p>
          <p>
            Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
            d&uuml;zeltilmesini isteme
          </p>
          <p>
            Kişisel verilerin KVKK ve ilgili diğer mevzuata uygun olarak
            silinmesini veya yok edilmesini isteme
          </p>
          <p>
            İşlenen kişisel verilerin d&uuml;zeltilmesi, silinmesi veya yok
            edilmesi gibi işlemlerin, kişisel verilerin aktarıldığı
            &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme
          </p>
          <p>
            Kendisi aleyhine bir sonucun ortaya &ccedil;ıkmasına neden olan
            kişisel verilerin otomatik sistemler vasıtasıyla analiz edilmesine
            itiraz etme
          </p>
          <p>
            Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarar
            uğraması halinde zararın giderilmesini talep etme
          </p>
          <p>
            Bu haklarınızı kullanmak i&ccedil;in ndkdanismanlik.com ile
            iletişime ge&ccedil;ebilirsiniz. İletişim bilgileri aşağıdaki
            gibidir:
          </p>
          <p>NDK PSİKOLOJİK DANIŞMANLIK MERKEZİ</p>
          <p>
            Adres : G&ouml;ztepe Mah. Mustafa Mazhar Sok. Kumru Apt. No:8/2 Kadık&ouml;y
            - İSTANBUL
          </p>
          <p>Tel: 0537 437 7891</p>
          <p>E-posta: bilgi@ndkdanismanlik.com</p>
          <p>
            Bu metin, ilgili kişilerin erişimine uygun yollarla sunulduğunda ve
            ndkdanismanlik.com'un onayı olmaksızın yayınlandığında,
            ndkdanismanlik.com, kişileri bilgilendirme
            y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirmiş sayılır.
          </p>
        </p>
      </div>
    </Layout>
  );
}
