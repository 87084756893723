import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import { useParams } from "react-router-dom";
import ContactForm from "../components/elements/contact-form";
import axios from "axios";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { FaPhone } from "react-icons/fa";
import ServiceItem from "../components/elements/service-item";

export default function ServiceDetailView() {
  const params = useParams();
  const { t } = useTranslation();
  const activeLanguage = i18n.language;

  const [infos, setInfos] = useState({});
  const getInfos = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "contact-infos/1")
      .then((res) => {
        if (res.data && res.data.status) {
          setInfos(res.data.data);
        }
      })
      .catch((err) => {
        setInfos({});
        console.log(err);
      });
  };
  useEffect(() => getInfos(), []);

  const [service, setService] = useState({});
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "services/" + params.slug)
      .then((res) => {
        if (res && res.data.status) {
          const data = res.data.data;

          const doctorData = {
            title: data.title,
            created_at: data.created_at,
            content: data.content,
            description: data.description,
            original: process.env.REACT_APP_API_URL + data.original,
          };
          setService(doctorData);
        } else {
          console.log("error");
          setService({});
        }
      })
      .catch((err) => {
        setService([]);
        console.error(err);
      });
  }, [activeLanguage]);

  const [services, setServices] = useState([]);

  const getData = async () => {
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + "services"
      );
      if (result && result.data) {
        const { status, message, data } = result.data;
        if (status) {
          const serviceDatas = data.filter(
            (x) => x.lang_code === activeLanguage
          );
          setServices(serviceDatas);
        } else {
          setServices([]);
        }
      }
    } catch (error) {
      console.log(error);
      setServices([]);
    }
  };

  useEffect(() => {
    getData();
  }, [activeLanguage]);

  return (
    <Layout pageTitle={service.title}>
      <div className="md:container md:mx-auto px-5 mb-5">
        <div className="mt-10 font-bold text-center text-3xl mb-4 text-main">
          <h1 id="page-title" className="">{service.title}</h1>
        </div>

        <div className="">
          <div
            className=" w-full h-72 bg-no-repeat bg-center mb-16"
            style={{
              backgroundImage: `url(${service.original})`,
            }}
          />
        </div>
        <div className="md:flex ">
          <div className="md:w-9/12 ">
            <div className="text-main md:pr-6 mb-20">
              <p dangerouslySetInnerHTML={{ __html: service.content }} />
            </div>
            <div>
              <div className="md:flex flex-wrap mb-20 md:container md:mx-auto md:pr-6 ">
                {services.map((s, index) => {
                  if (index <= 6) {
                    const kelime = s.description.split(" ");
                    const description = kelime.slice(0, 10).join(" ") + "...";
                    return (
                      <ServiceItem
                        key={index}
                        title={s.title}
                        description={description}
                        image={process.env.REACT_APP_API_URL + s.small}
                        href={
                          "/hizmetlerimiz/" + service.slug
                        }
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="md:w-3/12  ">
            <div className=" flex bg-gray-300 flex-col p-5  shadow-xl mb-5 rounded-xl">
              <h2 className="font-semibold text-lg mb-2">
                İletişim Bilgilerimiz
              </h2>

              {infos && infos.phone_1 && (
                <div className="flex flex-col  ">
                  <span className="mb-2">{infos.phone_1}</span>
                  <span> {infos.address}</span>
                </div>
              )}
            </div>
            <div className=" flex flex-col p-5  bg-gray-300 mb-5 shadow-xl rounded-xl">
              <p className="font-semibold text-lg mb-2">
                Bizimle iletişime geçebilirsiniz
              </p>

              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
