import React from "react";
import { useAuthHeader, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { Navigate, useLocation } from "react-router-dom";
import { isTokenExpired, splitToken } from "../utils";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import CheckActions from "../components/partials/check-actions";

const PrivateRoute = ({ checkPayment = true, children }) => {
  const location = useLocation();
  const authHeader = useAuthHeader();
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();

  const token = splitToken(authHeader());

  if (token.payload) {
    const userInfo = decodeToken(token.payload);

    if (userInfo.userType && userInfo.userType == "3") {
      if (isTokenExpired(userInfo)) {
        toast("Oturum Süresi Dolması Nedeniyle Çıkış Yapılıyor");
        signOut();
        return <Navigate to="/" state={{ from: location }} replace />;
      } else if (!isAuthenticated()) {
        return <Navigate to="/" state={{ from: location }} replace />;
      }
    } else {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <CheckActions checkPayment={checkPayment}>{children}</CheckActions>;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default PrivateRoute;
