import React from "react";

function Steps({ number, title, text }) {
  return (
    <div className="md:w-1/3 md:px-4 md:pb-10">
      <span className=" bg-primary flex items-center mb-4 justify-center w-9 h-9 mb-1 text-lg rounded-full font-bold">
        {number}
      </span>
      <h2 className="font-bold text-xl pb-2"> {title}</h2>
      <p className="pb-10">{text}</p>
    </div>
  );
}

export default Steps;
