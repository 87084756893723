import React from "react";
import cn from "classnames";
import { BiTimeFive } from "react-icons/bi";

function Option({ label = "", selected = false, disabled = false, onClick = () => {} }) {
  return (
    <li
      className={cn([
        "text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100",
        { "bg-gray-100": selected },
        selected && "bg-gray-200",
        disabled && "opacity-50 pointer-events-none", // Apply disabled styles
      ])}
      id="listbox-option-0"
      onClick={!disabled ? onClick : undefined} // Disable the click event if option is disabled
      
    >
      <div className="flex items-center">
        <span className="font-normal ml-3 block truncate">{label}</span>
      </div>

      <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
        <BiTimeFive />
      </span>
    </li>
  );
}

export default Option;
