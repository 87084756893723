import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import PageHeader from "../components/elements/page-header";
import DoctorsItem from "../components/elements/doctors-item";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18n from "i18next";

function DoctorsView() {
  const [doctors, setDoctors] = useState([]);
  const { t } = useTranslation();
  const activeLanguage = i18n.language;

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };


  const getAllActiveDoctors = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "doctors/active", {
        params: {
          search: searchTerm
        }
      }
      );

      const result = response.data;
      if (result && result.status) {
        setDoctors(result.data);
      }
    } catch (error) {
      console.log(error);
      setDoctors([]);
    }
  };

  useEffect(() => {
    getAllActiveDoctors();
  }, [activeLanguage]);

  return (
    <Layout pageTitle={t("global_header_psychologists")}>
      <PageHeader
        page={t("global_header_psychologists")}
        home={t("global_header_home")}
        text={"-" + t("global_header_psychologists")}
        image="doctors-image"
      />
      <div className="md:container md:mx-auto md:mx-2 mx-5 ">

        <div className="flex flex-wrap ">
          {doctors.map((doctor) => {
            if (searchTerm && !doctor.name_surname.toLowerCase().includes(searchTerm.toLowerCase())) {
              return null;
            }

            return (
              <DoctorsItem
                key={doctor.id}
                image={process.env.REACT_APP_API_URL + doctor.medium} //TODO: image nede çalışmıyor localde??
                name={doctor.name_surname}
                section={doctor?.subTitles.find((x) => x.lang_code === activeLanguage)?.sub_title}
                href={"/ekibimiz/" + doctor.slug}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  );
}

export default DoctorsView;
