import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReactCardFlip from "react-card-flip";
import Layout from "../components/partials/layout";
import { toast } from "react-toastify";
import cn from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import getDay from "date-fns/getDay";
import moment from "moment";
import "moment/locale/tr";
import { HiSelector } from "react-icons/hi";
import Option from "../components/base/option";
import InputMask from "react-input-mask";
import tr from "date-fns/locale/tr";
import {
  addLocalStorage,
  createLocalStorage,
  clearLocalStorage,
  deleteLocalStorage,
  getLocalStorage,
} from "../utils";

import { useSignIn } from "react-auth-kit";

import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useNavigate } from "react-router-dom";

import useWindowSize from "../hooks/useWindowSize";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

export default function LoginView() {
  const [checked, setChecked] = useState(false);

  const [loginLoading, setLoginLoading] = useState(false);
  const signIn = useSignIn();
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  let navigate = useNavigate();
  moment.locale("tr");
  registerLocale("tr", tr);

  const location = useLocation();

  useEffect(() => {
    setMode(location.pathname === "/giris-yap");
  }, [location]);

  const [mode, setMode] = useState(location.pathname === "/giris-yap");
  const [startDate, setStartDate] = useState();
  const [openDate, setOpenDate] = useState(false);
  const [showList, setShowList] = useState(false);
  const [times, setTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [appointmentStatus, setAppointmentStatus] = useState(true);

  const setTimesArea = () => {
    const startTime = "07:00";
    const endTime = "20:00";

    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    const diff = end.diff(start, "minutes");
    const step = 30;
    const tmp = [];
    for (let i = 0; i <= diff / step; i++) {
      const time = moment(start).add(step * i, "minutes");
      tmp.push(time.format("HH:mm"));
    }
    setTimes(tmp);
  };

  useEffect(() => {
    setTimeout(() => setOpenDate(false), 300);
  }, [startDate]);

  const getHours = (date) => {
    const now = moment();
    const old = moment(date);
    return now.diff(old, "hours");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (appointmentStatus) {
      const form = e.target;

      if (!checked) {
        toast.error("KVKK Aydınlatma Metni'ni Onaylayınız");
      } else {
        const datas = [
          {
            input: form.name,
            text: "Lütfen adınızı giriniz.",
          },
          {
            input: form.email,
            text: "Lütfen e-posta adresinizi giriniz.",
          },
          {
            input: form.phone,
            text: "Lütfen telefon numaranızı giriniz.",
          },
        ];

        let saveStatus = true;

        datas.map((item) => {
          if (item.input.value.trim().length === 0) {
            toast.error(item.text);
            item.input.focus();
            saveStatus = false;
          }
        });

        if (!startDate) {
          toast.error("Lütfen tarih seçiniz.");
          saveStatus = false;
        }

        if (!selectedTime) {
          toast.error("Lütfen saat seçiniz.");
          saveStatus = false;
        }

        if (saveStatus) {
          const saveData = {
            name_surname: form.name.value,
            email: form.email.value,
            phone: form.phone.value,
            date_time:
              moment(startDate).format("YYYY-MM-DD") + " " + selectedTime,
            appointment_type: form.appointment_type.value,
          };

          axios
            .post(process.env.REACT_APP_API_URL + "pre-appointments", saveData)
            .then((res) => {

              if (res.data.status) {
                toast.success("Randevunuz başarıyla kaydedildi.");
                form.reset();
                form.phone.value = "";
                setStartDate(new Date());
                setSelectedTime(null);

                const oldData = getLocalStorage("lastAppointment");

                const newData = {
                  appointment_time: new Date().getTime(),
                  count: oldData && oldData.count ? oldData.count + 1 : 1,
                };

                createLocalStorage("lastAppointment", newData);
              } else {
                toast.error(res.data.message);
              }
            });
        }
      }
    } else {
      toast.error(
        "Çok fazla ön randevu talep edildi. Lütfe daha sonra tekrar deneyiniz."
      );
    }
  };

  const loginForm = async (e) => {
    e.preventDefault();
    try {
      setLoginLoading(true);

      const email = e.target.email.value;
      const password = e.target.password.value;

      if (email.trim().length === 0 || password.trim().length === 0) {
        toast.error(t("please_email_or_password"));
        return;
      } else {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "clients/login",
          {
            email,
            password,
          }
        );

        if (response && response.data) {
          const data = response.data;
          if (data.status) {
            const signDatas = {
              token: data.data.token,
              expiresIn: 60 * 24,
              tokenType: "Bearer",
              authState: data.data.user,
            };
            if (signIn(signDatas)) {
              toast.success(data.message);
              navigate("/hesabim");
            }
          } else {
            toast.error(data.message);
            setLoginLoading(false);
          }
        } else {
          setLoginLoading(false);
          toast.error(t("someting_error"));
        }
      }
    } catch (error) {
      setLoginLoading(false);
      toast.error(t("someting_error"));
    }
  };

  const isNotWeekDay = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const isWeekDay = (date) => {
    const day = getDay(date);
    return day === 0 || day === 6;
  };

  useEffect(() => {
    setTimesArea();

    if (isWeekDay(new Date())) {
      // get next monday
      const nextMonday = new Date();
      nextMonday.setDate(
        nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
      );
      setStartDate(nextMonday);
    } else {
      setStartDate(new Date());
    }

    const oldData = getLocalStorage("lastAppointment");
    if (oldData) {
      if (getHours(oldData.appointment_time) >= 12) {
        clearLocalStorage("lastAppointment");
        setAppointmentStatus(true);
      } else if (oldData.count > 3 && getHours(oldData.appointment_time) < 12) {
        setAppointmentStatus(false);
      }
    }
  }, []);

  return (
    <Layout>
      <div className="bg-lightPrimary py-14  ">
        <div className="md:container md:mx-auto px-5 flex justify-around">
          <div>
            <div
              className={cn([
                "fixed left-0 top-0  w-full h-full z-50  justify-center items-center",
                openDate ? "flex" : "hidden",
              ])}
              style={{ background: "rgba(0,0,0,.7) " }}
            >
              <div
                className="absolute left-0 top-0 w-full h-full "
                onClick={() => setOpenDate(false)}
              />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                inline
                filterDate={isNotWeekDay}
                minDate={new Date()}
                locale="tr"
                className="red-border"
                style={{ zIndex: 9999 }}
              />
            </div>

            <ReactCardFlip
              isFlipped={mode}
              flipDirection={
                windowSize.width <= 600 ? "vertical" : "horizontal"
              }
            >
              <div>
                <div className=" border-2 border-primary px-7 py-5 bg-white rounded-2xl ">
                  <h3 className=" text-main font-bold md:text-xl text-lg mb-2 ">
                    {t("login_make_appointment")}
                  </h3>
                  <p className=" mb-6 ">{t("login_make_appointment_text")}</p>

                  <form
                    className=" w-full max-w-lg mb-8"
                    onSubmit={handleSubmit}
                  >
                    <div className=" flex flex-wrap -mx-3 mb-4 px-3 rounded-xl">
                      <label
                        className="block  tracking-wide text-textGrey text-base font-semibold  mb-2"
                        htmlFor="grid-first-name"
                      >
                        {t("contact_form_name")}
                        <span className="text-red-600 text-lg">*</span>
                      </label>
                      <input
                        className="appearance-none block w-full  text-gray-700 border-2 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary "
                        id="grid-first-name"
                        type="text"
                        placeholder={t("contact_form_name")}
                        name="name"
                        required
                      />
                    </div>

                    <div className="flex flex-wrap -mx-3 rounded-xl">
                      <div className="w-full mb-4 px-3">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("contact_form_email")}
                          <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                          className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                          id="email"
                          type="email"
                          placeholder={t("contact_form_email")}
                          name="email"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 rounded-xl">
                      <div className="w-full px-3 mb-4">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("contact_form_phone")}
                          <span className="text-red-600 text-lg">*</span>
                        </label>

                        <InputMask
                          className="appearance-none block w-full  text-gray-700 border
                        rounded py-3 px-4 mb-3 leading-tight focus:outline-none
                         focus:bg-white focus:border-primary"
                          name="phone"
                          placeholder={t("contact_form_phone")}
                          required
                          alwaysShowMask
                          mask="+\90 999 999 9999"
                          maskChar={17}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3  text-main">
                      <div className="w-full mb-4 px-3">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("login_date_time")}
                          <span className="text-red-600 text-lg"> *</span>
                        </label>

                        <div className="w-full md:flex  ">
                          <input
                            className="appearance-none
                         block w-full md:w-2/3  mr-2  text-gray-700
                          rounded py-3 px-4 mb-3 leading-tight focus:outline-none border-2 cursor-pointer focus:border-primary"
                            id="date_time"
                            type="text"
                            name="date_time"
                            placeholder={t("login_date")}
                            value={moment(startDate).format("DD MMMM YYYY")}
                            onClick={() => setOpenDate(true)}
                            required
                            onFocus={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                          />

                          <div className="md:w-1/3 relative ">
                            <button
                              type="button"
                              className="relative w-full
                            shadow-sm rounded py-3 px-4 mb-3 border-2 focus:border-primary cursor-pointer
                            text-left cursor-default
                           "
                              aria-haspopup="listbox"
                              aria-expanded="true"
                              aria-labelledby="listbox-label"
                              onClick={() => {
                                setShowList(!showList);
                              }}
                            >
                              <span className="flex items-center">
                                <span className=" block text-textGrey truncate">
                                  {selectedTime
                                    ? selectedTime
                                    : t("login_time")}
                                </span>
                              </span>
                              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <HiSelector />
                              </span>
                            </button>
                            {showList && (
                              <>
                                <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                  {times.map((time) => {
                                    // Check if the time has passed
                                    const currentTime = moment();
                                    const selectedDateTime = moment(
                                      `${moment(startDate).format(
                                        "YYYY-MM-DD"
                                      )} ${time}`,
                                      "YYYY-MM-DD HH:mm"
                                    );
                                    const isPastTime =
                                      selectedDateTime.isBefore(
                                        currentTime,
                                        "minute"
                                      );

                                    return (
                                      <Option
                                        key={time}
                                        label={time}
                                        selected={selectedTime === time}
                                        disabled={isPastTime} // Disable the option if it's a past time
                                        onClick={() => {
                                          setSelectedTime(time);
                                          setShowList(false);
                                        }}
                                      />
                                    );
                                  })}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Appointment Type */}

                    <div className="flex flex-wrap -mx-3 mb-4 px-3 rounded-xl">
                      <label
                        className="block tracking-wide text-textGrey text-base font-semibold mb-2"
                        htmlFor="grid-appointment-type"
                      >
                        {t("contact_form_appointment_type")}
                        <span className="text-red-600 text-lg">*</span>
                      </label>
                      <Select
                        className="w-full"
                        id="grid-appointment-type"
                        name="appointment_type"
                        options={[
                          {
                            value: t("contact_form_appointment_type_1"),
                            label: t("contact_form_appointment_type_1"),
                          },
                          {
                            value: t("contact_form_appointment_type_2"),
                            label: t("contact_form_appointment_type_2"),
                          },
                        ]}
                        placeholder={t("contact_form_appointment_type")}
                        required
                      />
                    </div>

                    <div className=" flex items-center mb-3">
                      <input
                        type="checkbox"
                        name="terms"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        className="mr-2  "
                        id="checkbox"
                        required
                      />
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t("global_kvkk_text"),
                        }}
                        className="text-sm text-main "
                        htmlFor="checkbox"
                      />
                    </div>

                    <div className="  mb-6">
                      <button
                        className="text-white
                           border-2 w-full
                           flex items-center justify-center
                          py-2
                           rounded-lg
                           font-bold
                           duration-75
                           border-main
                          bg-main
                           hover:bg-white hover:text-main
                         text-base"
                      >
                        {t("global_make_appointment")}
                      </button>
                    </div>

                    <div
                      text={t("login_form_or")}
                      className="text-center login-line   "
                    >
                      <h1 className="h-1  mx-auto bg-main w-2/3" />
                    </div>
                  </form>
                  <p className="pb-2 ">{t("login_appointment_text")}</p>
                  <button
                    onClick={() => {
                      window.scroll(0, 0);
                      setMode(true);
                    }}
                    className="text-main
           border-2 w-full
           flex items-center justify-center
          py-2
           rounded-lg
           font-bold
           duration-75 bg-white
          border-main
           hover:bg-main hover:text-white
         text-base"
                  >
                    {t("global_login")}
                  </button>
                </div>
              </div>

              <div>
                <div className=" border-2 border-primary px-7 py-5 bg-white rounded-2xl ">
                  <h3 className=" text-main font-bold md:text-xl  text-lg mb-2 ">
                    {t("global_login")}
                  </h3>
                  <p className=" mb-6">{t("login_appointment_text")}</p>

                  <form className=" w-full max-w-lg mb-8" onSubmit={loginForm}>
                    <div className="flex flex-wrap -mx-3 rounded-xl">
                      <div className="w-full mb-4 px-3">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("contact_form_mail")}
                          <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                          className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-primary"
                          id="email"
                          type="email"
                          placeholder={t("contact_form_mail")}
                          name="email"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3  text-main">
                      <div className="w-full mb-4 px-3">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("global_password")}
                          <span className="text-red-600 text-lg"> *</span>
                        </label>

                        <input
                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-primary cursor-pointer"
                          id="password"
                          type="password"
                          name="password"
                          placeholder={t("login_form_password")}
                        />
                      </div>
                    </div>

                    <div className="  mb-6">
                      <button
                        disabled={loginLoading}
                        className={cn([
                          "text-white border-2 w-full flex items-center justify-center py-2 rounded-lg font-bold duration-75 border-main bg-main  hover:bg-white hover:text-main text-base",
                          loginLoading ? "opacity-70  " : "cursor-pointer",
                        ])}
                      >
                        {t("global_login")}
                      </button>
                    </div>

                    <div
                      text={t("login_form_or")}
                      className="text-center login-line  "
                    >
                      <h1 className="h-1  mx-auto bg-main w-2/3" />
                    </div>
                  </form>
                  <p className="pb-2 ">{t("login_form_text")}</p>
                  <button
                    onClick={() => {
                      window.scroll(0, 0);
                      setMode(false);
                    }}
                    className="text-main
           border-2 w-full
           flex items-center justify-center
          py-2
           rounded-lg
           font-bold
           duration-75 bg-white
          border-main
           hover:bg-main hover:text-white
         text-base"
                  >
                    {t("login_make_appointment")}
                  </button>
                </div>
              </div>
            </ReactCardFlip>
          </div>
          <div className=" lg:flex lg:items-center lg:justify-center hidden">
            <div className="login-image" />
          </div>
        </div>
      </div>
    </Layout>
  );
}
