import React from "react";
import BlogItemImage from "../../images/blog-item-image.jpg";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ServiceItem({ title, description, href = "#", image = "" }) {
  const { t } = useTranslation();
  return (
      <div className="md:px-2 md:w-1/3 mb-4">
        <div className="blog-items shadow-2xl rounded-2xl flex flex-col h-full">
          <div className="relative flex justify-center mb-8">
            <img className="w-full" src={image} alt=""/>
          </div>
          <div className="flex flex-col flex-1 mx-4">
            <div className="text-lg text-main font-bold pr-6 mb-5">
              <p>{title}</p>
            </div>
            <div className="text-lg text-main pr-6 mb-5 flex-1">
              <p>{description}</p>
            </div>
            <div className="flex justify-end pb-5">
              <Link
                  to={href}
                  className="text-white flex items-center justify-between md:pr-3 md:pl-3 px-4 py-2 rounded-lg font-bold duration-75 bg-main hover:bg-primary md:text-sm md:w-5/12 w-7/12"
              >
                {t("blog_read_more")}
                <FaPlus className="text-lg"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ServiceItem;
