import React from "react";
import { MdOutlineErrorOutline } from "react-icons/md";

export default function AccountCard({ time, text, fullTime }) {
  return (
    <div className="  md:w-full w-72 h-24 flex mb-2  bg-white">
      <div className="bg-primary   w-2 h-full " />
      <MdOutlineErrorOutline className=" relative left-2 top-4 w-5 h-5 mr-4 text-primary  " />
      <div className="relative  top-3">
        <span className="text-xs font-semibold  uppercase text-textGrey ">
          {time}
        </span>
        <h3 className="text-main  font-semibold mb-1 ">{text}</h3>
        <p className="text-textGrey text-sm">{fullTime}</p>
      </div>
    </div>
  );
}
