import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/partials/layout";
import AccountCard from "../components/elements/account-card";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from "@fullcalendar/core/locales/tr";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import "moment/locale/tr";
import { BiTime } from "react-icons/bi";
import {
  BsFilePerson,
  BsViewList,
  BsInfoSquareFill,
  BsChevronRight,
  BsChevronDown,
} from "react-icons/bs";
import { AiFillWarning } from "react-icons/ai";
import Modal from "../components/elements/modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import cn from "classnames";

import PrivateRoute from "../auth/PrivateRoute";

import { useAuthHeader } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function MyAppointmentss() {
  const { t } = useTranslation();
  const authHeader = useAuthHeader();
  moment.locale("tr");

  const [loader, setLoader] = useState(false);
  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [clientDatas, setClientDatas] = useState({});
  const [progressId, setProgressId] = useState(null);

  const [selectDateCount, setSelectDateCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(selectDateCount);
  const [clientDateSelectInfo, setClientDateSelectInfo] = useState(null);

  const [calenderSelectMode, setCalenderSelectMode] = useState(false);
  const [validRange, setValidRange] = useState(null);

  const [clientSelectDateTimes, setClientSelectDateTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [noAcceptDateTimes, setNoAcceptDateTimes] = useState([]);

  const [timeSelectModal, setTimeSelectModal] = useState(false);

  const [modalTimeList, setModalTimeList] = useState([]);

  const [applyModal, setApplyModal] = useState(null);
  const [periodDuration, setPeriodDuration] = useState(null);

  const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  const minutes = ["00", "15", "30", "45"];

  // const [mustPays, setMustPays] = useState([]);

  const getAllAppointments = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "clients/my-all-appointments",
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response && response.data && response.data.status) {
        const data = response.data.data;

        const ev = data.map((x) => {
          const date = moment(x.period_date).format("YYYY-MM-DD");

          const startTime = x.period_start_time.split(":");
          const endTime = x.period_end_time.split(":");

          return {
            id: x.id,
            title: x.doctor_name_surname,
            start: date + " " + x.period_start_time,
            end: date + " " + x.period_end_time,
            extendedProps: {
              date: date,
              time:
                startTime[0] +
                ":" +
                startTime[1] +
                " - " +
                endTime[0] +
                ":" +
                endTime[1],
              therapy_type_id: x.therapy_type_id,
              doctor_name_surname: x.doctor_name_surname,
              therapy_type_name: x.therapy_type_name,
            },
          };
        });

        setEvents(ev);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEventsWithin10Days = () => {
    // events filter for 10 days
    const today = new Date();
    const add10days = new Date(today.getTime() + 10 * 24 * 60 * 60 * 1000);

    let find = [];

    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      const eventDate = new Date(event.start);
      if (eventDate >= today && eventDate <= add10days) {
        const date = moment(event.start).format("DD MMMM YYYY");
        const time = moment(event.start).format("HH:mm");
        const left = moment(event.start).fromNow();

        find.push({
          id: event.id,
          text: event.title.replaceAll("_", " - "),
          fullTime: `${date} Tarihli, Saat: ${time} randevu ${left} `,
          unix: moment(event.start).unix(),
        });
      }
    }

    // short by  unix date
    find = find.sort((a, b) => {
      return a.unix - b.unix;
    });
    setNotifications(find);
  };

  const userDatas = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "clients/me",
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response && response.data.status) {
        const { data } = response.data;
        setClientDatas(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (events && events.length > 0) {
      getEventsWithin10Days();
    }
  }, [events]);

  const getActions = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "clients/get-actions",
      {
        headers: {
          Authorization: authHeader(),
        },
      }
    );

    if (response && response.data.status) {
      const data = response.data.data;
      if (data.date_select_status) {
        // client-choose-date
        const dateChoseDatas = await axios.get(
          process.env.REACT_APP_API_URL + "chose-date/client-choose-date",
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );

        const data = dateChoseDatas.data;
        if (data.status) {
          const dateInfos = data.data;

          setClientDateSelectInfo(dateInfos);
          setSelectDateCount(dateInfos.period_counter);
          setSelectedCount(dateInfos.period_counter);
          setProgressId(dateInfos.id);

          if (dateInfos.period_type === 1) {
            // her gün
            setNoAcceptDateTimes([]);
          } else if (dateInfos.period_type === 2) {
            // her hafta
            setNoAcceptDateTimes([]);
          } else if (dateInfos.period_type === 3) {
            // her ay
            setNoAcceptDateTimes([]);
          }
        }
      }
    }
  };

  useEffect(() => {
    getAllAppointments();
    // getMustPay();
    userDatas();
    getActions();
  }, []);

  const handleDateClick = (arg) => {
    if (calenderSelectMode) {
      const newDate = new Date(arg.date);
      const today = new Date();

      if (newDate > today && newDate.getDay() !== 0) {
        setSelectedDate(newDate);

        const findDate = noAcceptDateTimes.filter(
          (x) => x.date === moment(newDate).format("YYYY-MM-DD")
        );

        const listOfTime = hours.map((hour) => {
          hour = hour < 10 ? "0" + hour : hour;

          const tmpHours = minutes.map((minute) => {
            return hour + ":" + minute;
          });

          return {
            id: hour,
            head: hour + ":00",
            content: minutes.map((minute) => {
              // find findDate in tmpHours startTime or endTime
              const findStartTime = tmpHours.find((x) => {
                return findDate.find((y) => {
                  return y.startTime === x;
                });
              });

              const findEndTime = tmpHours.find((x) => {
                return findDate.find((y) => {
                  return y.endTime === x;
                });
              });

              // console.log(
              //   "findStartTime",
              //   findStartTime,
              //   "findEndTime",
              //   findEndTime
              // );
              //
              // var startTime = moment(findStartTime, "HH:mm:ss a");
              // var endTime = moment(findEndTime, "HH:mm:ss a");
              //
              // console.log(
              //   "duration: ",
              //   moment.duration(endTime.diff(startTime)).asMinutes()
              // );

              const isDisable = findStartTime || findEndTime;
              return (
                <button
                  className={cn([
                    "rounded-2xl mr-2 text-center border  text-white px-3 ",
                    isDisable
                      ? " bg-gray-300 border-gray-400 "
                      : "border-main bg-main hover:opacity-70 duration-75",
                  ])}
                  disabled={isDisable}
                  onClick={() => {
                    setTime(newDate, hour + ":" + minute);
                  }}
                  key={hour + minute}
                >
                  {hour}:{minute}
                </button>
              );
            }),
          };
        });

        setModalTimeList(listOfTime);

        setTimeSelectModal(true);
      }
    }
  };

  const setTime = (date, time) => {
    const dateTime = moment(date).format("YYYY-MM-DD") + " " + time;
    const tmpSelectedDates = clientSelectDateTimes;

    const tmpNoAcceptDateTimes = noAcceptDateTimes;

    const startTime = moment(dateTime, "YYYY-MM-DD HH:mm").add(
      periodDuration,
      "minutes"
    );
    const endTime = moment(startTime, "YYYY-MM-DD HH:mm").format("HH:mm");

    const tmpDt = {
      date: moment(date).format("YYYY-MM-DD"),
      startTime: time,
      endTime: endTime,
      duration: periodDuration,
    };

    tmpSelectedDates.push(tmpDt);
    setClientSelectDateTimes(tmpSelectedDates);
    setSelectedCount(clientSelectDateTimes.length);
    setTimeSelectModal(false);

    tmpNoAcceptDateTimes.push(tmpDt);

    setNoAcceptDateTimes(tmpNoAcceptDateTimes);
  };

  useEffect(() => {
    if (calenderSelectMode && selectedCount === selectDateCount) {
      setCalenderSelectMode(false);
      setTimeSelectModal(false);
      setApplyModal(true);
    }
  }, [selectedCount]);

  const eventClick = (e) => {
    console.log("eventClick", e);
  };

  const dateSelectedModeActive = async () => {
    setLoader(true);
    try {
      if (calenderSelectMode) {
        setCalenderSelectMode(false);
        setNoAcceptDateTimes([]);
        setClientSelectDateTimes([]);
      } else {
        const doctor_id = clientDateSelectInfo.doctor_id;
        const client_id = clientDateSelectInfo.client_id;
        const period_duration = clientDateSelectInfo.period_duration;
        const period_counter = clientDateSelectInfo.period_counter;
        const period_type = clientDateSelectInfo.period_type;
        const therapy_type_id = clientDateSelectInfo.therapy_type_id;

        setPeriodDuration(period_duration);

        const doctorDates = await axios.get(
          process.env.REACT_APP_API_URL + "doctors/fuel-dates/" + doctor_id
        );
        if (doctorDates.data.status) {
          const doctorDatesData = doctorDates.data.data.map((x) => {
            const date = moment(x.period_date).format("YYYY-MM-DD");
            const startTime = moment(x.period_start_time, "HH:mm").format(
              "HH:mm"
            );
            const endTime = moment(x.period_end_time, "HH:mm").format("HH:mm");

            return {
              date,
              startTime,
              endTime,
            };
          });
        }

        setCalenderSelectMode(true);
        setValidRange([
          {
            start: moment().format("YYYY-MM-DD"),
            end: moment().add(10, "days").format("YYYY-MM-DD"),
          },
        ]);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const applySelectedDates = async () => {
    console.log("applySelectedDates");
  };

  const renderEventContent = (eventInfo) => {
    const { extendedProps } = eventInfo.event;

    return (
      <div className="border border-main rounded px-2 py-1 block w-full bg-gray-100 cursor-pointer hover:bg-gray-300 hover:shadow">
        <b className="flex items-center w-full  ">
          <BiTime /> <span className="ml-2">{extendedProps.time}</span>
        </b>
        <div className="flex items-center w-full  ">
          <BsFilePerson />{" "}
          <span className="ml-2">{extendedProps.doctor_name_surname}</span>
        </div>
        <div className="flex items-center w-full  ">
          <BsViewList />
          <span className="ml-2">{extendedProps.therapy_type_name}</span>
        </div>
      </div>
    );
  };

  const approveDates = async () => {
    setLoader(true);
    try {
      let tmpCounter = 1;
      const dates = clientSelectDateTimes.map((x) => {
        x.period_counter = tmpCounter;
        tmpCounter++;
        return x;
      });
      const progress_id = progressId;

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "chose-date/approve-for-client",
        {
          dates,
          progress_id,
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response.data && response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => window.location.reload(), 1000);
      } else {
        toast.error(response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoader(false);
    }
  };

  return (
    <Layout type={true}>
      <PrivateRoute>
        <div className="md:container md:mx-auto px-5">
          <div className=" mx-auto border-2 md:px-8 px-2 py-4 my-6 rounded-2xl shadow-2xl ">
            <div className="md:flex ">
              <h3 className="text-main md:text-2xl font-bold mb-2 md:w-1/4  md:mr-2 mb-2 ">
                Randevularım
              </h3>

              <div className="md:w-3/4  md:mr-2 mb-2 ">
                {clientDateSelectInfo && selectDateCount > 0 && (
                  <>
                    <div className="border border-gray-900 rounded text-white px-2 py-1 mb-2 bg-main  ">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <span>
                            <BsInfoSquareFill />
                          </span>
                          <span className="ml-2">
                            {t("date_select_title").replace(
                              "[count]",
                              selectDateCount
                            )}
                          </span>
                        </div>

                        <div>
                          <button
                            onClick={dateSelectedModeActive}
                            className="border border-white px-3 py-1 rounded font-bold bg-primary hover:opacity-70"
                          >
                            {calenderSelectMode
                              ? t("cancel")
                              : t("date_select_button")}
                          </button>
                          {calenderSelectMode && (
                            <button
                              disabled={loader}
                              onClick={applySelectedDates}
                              className="border border-green-600 px-3 py-1 rounded font-bold bg-green-400 ml-2 hover:opacity-70"
                            >
                              {t("apply")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {calenderSelectMode && (
                      <div className="border border-red-900 rounded text-white px-2 py-1 mb-2 bg-red-600  ">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span>
                              <AiFillWarning />
                            </span>
                            <span className="ml-2">
                              {t("select_date_info").replace(
                                "[count]",
                                selectDateCount - clientSelectDateTimes.length
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="md:flex ">
              <div className="md:w-1/4  md:mr-2 mb-2 md:mb-0 ">
                <div className="rounded-xl border-2 border-primary h-full w-full">
                  <div className="rounded-xl  p-4 ">
                    <h3 className="font-bold text-xl text-main mb-4">
                      {t("incoming_aporiments")}
                    </h3>
                    <div
                      className=" relative flex gap-2 md:block accountHeight h-100 overflow-scroll"
                      style={{ minHeight: "100vh" }}
                    >
                      {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                          <div className="w-full" key={index}>
                            <AccountCard
                              text={notification.text}
                              fullTime={notification.fullTime}
                            />
                          </div>
                        ))
                      ) : (
                        <div className=" w-full block">
                          <div className="  w-full h-24 flex mb-2 ">
                            <div className="bg-textGrey   w-2 h-full " />
                            <h3 className="text-textGrey  w-full  font-semibold mb-1 flex items-center justify-center  ">
                              {t("no_incoming_aporiments")}
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" md:w-3/4  border-primary border-t-2 p-1 ">
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                  dateClick={handleDateClick}
                  initialView="dayGridMonth"
                  dayCellClassNames={(date) => {
                    const newDate = new Date(date.date);
                    const today = new Date();

                    // if noAcceptDateTimes in newDate find
                    const dateDisable = noAcceptDateTimes.find(({ date }) => {
                      date = new Date(date);
                      return (
                        date.getDate() === newDate.getDate() &&
                        date.getMonth() === newDate.getMonth() &&
                        date.getFullYear() === newDate.getFullYear()
                      );
                    });

                    return calenderSelectMode
                      ? newDate.getTime() < today.getTime() ||
                        newDate.getDay() === 0 ||
                        dateDisable
                        ? "bg-gray-200"
                        : "bg-green-300 hover:bg-green-500 cursor-pointer"
                      : "";
                  }}
                  validRange={(nowDate) => {
                    return calenderSelectMode && validRange && validRange;
                  }}
                  businessHours={{
                    daysOfWeek: [1, 2, 3, 4, 5, 6],
                    startTime: "07:00",
                    endTime: "18:00",
                  }}
                  height={690}
                  headerToolbar={{
                    start: "prev,next today",
                    center: "title",
                    end: "listWeek,dayGridMonth",
                  }}
                  events={events}
                  eventClick={eventClick}
                  locale={trLocale}
                  eventContent={renderEventContent}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="md"
          header="Zaman Seç"
          show={timeSelectModal}
          setShow={setTimeSelectModal}
        >
          <div className="px-4">
            <p className="mb-2">
              {t("plase_selecttime").replace(
                "[date]",
                moment(selectedDate).format("DD MMMM YYYY")
              )}
            </p>

            <div></div>

            <br />

            <div className="p-4">
              <Accordion
                allowZeroExpanded
                className="bg-gray-100 border rounded"
              >
                {modalTimeList.map((item) => (
                  <AccordionItem key={item.id} className="px-4 p-2  ">
                    <AccordionItemHeading className="font-bold border-b-2 pb-2 hover:bg-gray-300 cursor-pointer">
                      <AccordionItemButton className="flex  items-center">
                        <AccordionItemState className="mr-2">
                          {({ expanded }) =>
                            expanded ? <BsChevronDown /> : <BsChevronRight />
                          }
                        </AccordionItemState>
                        {item.head}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="bg-white p-2">
                      <div className="flex flex-wrap items-center">
                        {item.content}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
        </Modal>

        <Modal
          size="md"
          header="Gözden Geçir ve Onayla"
          show={applyModal}
          setShow={setApplyModal}
        >
          <div className="px-4">
            <table className="table-auto border-collapse table-auto w-full text-sm">
              <thead>
                <tr>
                  <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400   text-left">
                    Tarih
                  </th>
                  <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400   text-left">
                    Başlangıç Saati
                  </th>
                  <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400  text-left">
                    Bitiş Saati
                  </th>
                  <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400  text-left">
                    Süre (DK)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white  ">
                {clientSelectDateTimes.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 ">
                      {item.date}
                    </td>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 ">
                      {item.startTime}
                    </td>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 ">
                      {item.endTime}
                    </td>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 ">
                      {item.duration} DK
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="text-right">
              <button
                onClick={approveDates}
                disabled={loader}
                className="text-white border-2 w-full flex items-center justify-center py-2 rounded-lg font-bold duration-75  border-main bg-main hover:bg-white hover:text-main text-base"
              >
                {loader ? t("please_wait") : t("approve")}
              </button>
            </div>
          </div>
        </Modal>
      </PrivateRoute>
    </Layout>
  );
}
