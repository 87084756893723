import React from "react";
import cn from "classnames";

function InfoCard({ title, text, color }) {
  return (
    <div className="md:w-1/3  p-3 min-h-full ">
      <div className={cn([" flex rounded-3xl h-full shadow-2xl", color])}>
        <div className=" px-6 py-4  ">
          <h3 className=" font-bold md:text-2xl text-lg md:pb-3 pb-2 ">
            {title}
          </h3>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
