import React from "react";
import { FaPlus, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import cn from "classnames";

function BlogItem({ date, title, description, image, href = "#" }) {
  const { t } = useTranslation();

  const { width } = useWindowSize();

  const words = description.split(' '); 
  const content = words.length > 25
  ? words.slice(0, 25).join(' ') + "..."
  : description;


  return (
    <div className="md:px-2 md:w-1/4  mb-4 p-4  ">
      <div
        className="blog-items bg-white border border-gray-300 shadow-2xl rounded-2xl  h-full relative "
        style={{ minHeight: 280 }}
      >
        <div className="relative flex justify-center  ">
          <img width="100%" src={image} alt="" />
          <div className="bg-primary  absolute -bottom-5 text-white  rounded-full font-bold ">
            <span className="flex items-center w-12 h-12 justify-center text-center text-sm p-2 ">
              {date}
            </span>
          </div>
        </div>

        <div className=" mx-4 pt-8 ">
          <h2 className="text-lg  text-main font-bold  pr-6 mb-2"><Link to={href}>{title}</Link></h2>
          <div className=" mb-5">
            <p>{content}</p>
          </div>
        </div>

        <Link
          to={href}
          className={cn([
            ` text-white
                flex
                items-center
                justify-between
                md:pr-3
                md:pl-3
                px-4 py-2
                rounded-lg
                font-bold
                duration-75
                bg-main
                hover:bg-primary
                md:text-sm
                mx-auto
                w-11/12
                `,
            "absolute bottom-2 left-0 right-0",
          ])}
        >
          {t("blog_read_more")}
          <FaArrowRight className="text-lg " />
        </Link>
      </div>
    </div>
  );
}

export default BlogItem;
