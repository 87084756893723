import React from "react";
import { Link } from "react-router-dom";
import Button from "../base/button";

export default function DoctorsItem({ image, name, section, href = "#" }) {
  return (
    <>
      <Link to={href} className="md:w-1/3 mb-10 p-3">
        <div className="flex flex-col h-full text-main rounded-2xl shadow-2xl">
          <div className="pb-4">
            <img className="w-full rounded-t-2xl" src={image} alt={name} />
          </div>
          <div className="flex flex-col flex-1 mx-4">
            <div className="flex-1 pb-4">
              <h2 className="pb-1 font-bold text-xl">{name}</h2>

              <p className="text-base text-secondary font-medium">
                {section}
              </p>
            </div>
            <div className="flex justify-end pb-4">
              <Button
                  href={href}
                  text="Detay"
                  type="bg-main"
                  className="md:w-1/3"
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
