import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrivateRoute from "../auth/PrivateRoute";
import cn from "classnames";

export default function PaymentConfirmation() {
  const { t } = useTranslation();

  const [paymentStatus, setPaymentStatus] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);

  const { datas } = useParams();

  useEffect(() => {
    const data = JSON.parse(datas);
    setPaymentStatus(data.status);
    setTitle(data.message);

    if (data.status) {
      setContent(
        <>
          <a href="/hesabim" className="text-green-500">
            Devam Et
          </a>
        </>
      );
    } else {
      setContent(
        <>
          <p className=" mb-8">Bankanın mesajı: {data.data.message} </p>
          <a href="/hesabim" className="text-green-500">
            Devam Et
          </a>
        </>
      );
    }
  }, []);

  return (
    <Layout type={true}>
      <PrivateRoute checkPayment={false}>
        <div className="md:container md:mx-auto px-5">
          <div
            className=" mx-auto border-2 md:px-8 px-2 py-4 my-6 rounded-2xl shadow-2xl text-center pt-24"
            style={{ minHeight: 550 }}
          >
            <h3
              className={cn([
                " md:text-2xl mb-2 mb-2 font-bold mb-8",
                paymentStatus ? "text-green-500" : "text-red-500",
              ])}
            >
              {title}
            </h3>

            {content}
          </div>
        </div>
      </PrivateRoute>
    </Layout>
  );
}
