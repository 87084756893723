import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.scss";
import "./i18nextInit";

import { AuthProvider } from "react-auth-kit";

import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import App from "./App";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import dotenv from "dotenv";
dotenv.config();

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "tr"],
    fallbackLng: "tr",
    debug: false,
    detection: {
      order: ["cookie"],
      caches: ["cookie"],
    },
    backend: {
      loadPath:
        window.location.origin + `/assets/locales/{{lng}}/translations.json`,
    },
  });

ReactDOM.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
      >
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            newestOnTop={true}
            zIndex={99999}
          />

          <App />
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
