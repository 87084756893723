import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PrivateRoute from "../auth/PrivateRoute";
import { useAuthHeader } from "react-auth-kit";
import InputMask from "react-input-mask";
import moment from "moment";
import "moment/locale/tr";
import { toast } from "react-toastify";
import AccountCard from "../components/elements/account-card";

export default function AccountView() {
  moment.locale("tr");
  const { t } = useTranslation();
  const [formLoader, setFormLoader] = useState(false);

  const [notifications, setNotifications] = useState([]);

  const [userDatas, setUserDatas] = useState({
    birth_date: "",
    email: "",
    gender: "",
    name_surname: "",
    phone: "",
    addres: "",
  });

  const authHeader = useAuthHeader();

  const styles = {
    radioWhite: {
      border: "10px solid #90DDD0",
    },
    radioPink: {
      border: "10px solid #EF959D",
    },
    radioRed: {
      border: "10px solid #90DDD0",
    },
  };
  styles.radioPink["backgroundColor"] = "#EF959D";

  const genders = [
    { title: t("form_woman"), value: "Kadın" },
    { title: t("form_male"), value: "Erkek" },
    { title: t("form_specify"), value: "Belirtmek İstemiyorum" },
  ];

  const formSubmit = async (e) => {
    setFormLoader(true);
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const data = {};
      for (let entry of formData.entries()) {
        data[entry[0]] = entry[1];
      }

      const response = await axios.put(
        process.env.REACT_APP_API_URL + "clients/update-me",
        data,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response && response.data && response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response?.data?.message || t("someting_error"));
      }

      setFormLoader(false);
    } catch (error) {
      console.log("error:", error);
      setFormLoader(false);
      toast.error(t("someting_error"));
    }
  };

  const getUserDatas = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "clients/me",
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response && response.data.status) {
        const { data } = response.data;

        setUserDatas({
          birth_date: data?.birth_date,
          email: data.email,
          gender: data.gender,
          name_surname: data.name_surname,
          phone: data.phone,
          addres: data.addres,
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const getAllAppointments = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "clients/my-all-appointments",
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response && response.data && response.data.status) {
        const data = response.data.data;

        const ev = data.map((x) => {
          const date = moment(x.period_date).format("YYYY-MM-DD");

          const startTime = x.period_start_time.split(":");
          const endTime = x.period_end_time.split(":");

          return {
            id: x.id,
            title: x.doctor_name_surname,
            start: date + " " + x.period_start_time,
            end: date + " " + x.period_end_time,
            extendedProps: {
              date: date,
              time:
                startTime[0] +
                ":" +
                startTime[1] +
                " - " +
                endTime[0] +
                ":" +
                endTime[1],
              therapy_type_id: x.therapy_type_id,
              doctor_name_surname: x.doctor_name_surname,
              therapy_type_name: x.therapy_type_name,
            },
          };
        });

        let events = ev;

        // events filter for 10 days
        const today = new Date();
        const add10days = new Date(today.getTime() + 10 * 24 * 60 * 60 * 1000);

        let find = [];

        for (let i = 0; i < events.length; i++) {
          const event = events[i];
          const eventDate = new Date(event.start);
          if (eventDate >= today && eventDate <= add10days) {
            const date = moment(event.start).format("DD MMMM YYYY");
            const time = moment(event.start).format("HH:mm");
            const left = moment(event.start).fromNow();

            find.push({
              id: event.id,
              text: event.title.replaceAll("_", " - "),
              fullTime: `${date} Tarihli, Saat: ${time} randevu ${left} `,
              unix: moment(event.start).unix(),
            });
          }
        }

        // short by  unix date
        find = find.sort((a, b) => {
          return a.unix - b.unix;
        });
        setNotifications(find);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDatas();
  }, []);

  return (
    <Layout type={true}>
      <PrivateRoute>
        <div className="bg-gray-200">
          <div className="md:container md:mx-auto pb-20 ">
            <h2 className="text-2xl pt-2 mb-4 pl-4 text-main font-bold">
              {t("global_account")}
            </h2>

            <div className="md:flex justify-between w-full  ">
              <div className="md:w-1/2 p-4">
                <div className="rounded-xl shadow-2xl bg-white w-full h-full p-6">
                  <h3 className="font-bold text-xl text-main mb-4">
                    {t("account_header_title")}
                  </h3>

                  <form className=" mb-8" onSubmit={formSubmit}>
                    <div className=" flex flex-wrap mb-4 rounded-xl  ">
                      <label
                        className="block tracking-wide text-textGrey text-base font-semibold  mb-2"
                        htmlFor="grid-first-name"
                      >
                        {t("contact_form_name")}
                        <span className="text-red-600 text-lg">*</span>
                      </label>
                      <input
                        className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary"
                        id="grid-first-name"
                        type="text"
                        placeholder={t("contact_form_name")}
                        name="name_surname"
                        defaultValue={userDatas.name_surname}
                        required
                      />
                    </div>

                    <div className="flex flex-wrap  rounded-xl">
                      <div className="w-full  mb-4">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("form_date_of_birth")}
                          <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                          className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-primary"
                          id="phone"
                          type="date"
                          max={moment().format("YYYY-MM-DD")}
                          min={moment()
                            .subtract(100, "years")
                            .format("YYYY-MM-DD")}
                          name="birth_date"
                          placeholder={t("form_date_of_birth")}
                          required
                          defaultValue={
                            userDatas.birth_date
                              ? moment(userDatas.birth_date).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap  rounded-xl">
                      <div className="w-full  mb-4">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("contact_form_phone")}
                        </label>

                        <InputMask
                          className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-primary"
                          placeholder={t("contact_form_phone")}
                          alwaysShowMask
                          mask="+\90 999 999 9999"
                          maskChar={17}
                          disabled={true}
                          value={userDatas.phone}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap  rounded-xl">
                      <div className="w-full  mb-4">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("email")}
                        </label>

                        <input
                          className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-primary"
                          placeholder={t("contact_form_phone")}
                          disabled={true}
                          value={userDatas.email}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap  ">
                      <div className="w-full  mb-4">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("form_gender")}{" "}
                          <span className="text-red-600 text-lg">*</span>
                        </label>

                        <div>
                          <div className="flex md:flex-row flex-col gap-2  md:justify-between md:items-center">
                            {genders.map((gender, index) => (
                              <div className="  " key={index}>
                                <div className="">
                                  <label className="inline-flex border border-gray-200 rounded-xl hover:border-primary  px-6 py-3 w-full cursor-pointer items-center">
                                    <input
                                      type="radio"
                                      className="form-radio  cursor-pointer mr-4 h-5 w-5"
                                      name="gender"
                                      value={gender.value}
                                      checked={
                                        userDatas.gender &&
                                        userDatas.gender == gender.value
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className=" text-main font-semibold">
                                      {gender.title}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap  rounded-xl">
                      <div className="w-full  mb-4">
                        <label
                          className="block  tracking-wide text-textGrey text-base font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("form_address")}
                          <span className="text-red-600 text-lg">*</span>
                        </label>
                        <textarea
                          className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-primary"
                          id="adress"
                          name="addres"
                          placeholder={t("form_address")}
                          required
                          defaultValue={userDatas.addres}
                        />
                      </div>
                    </div>
                    <button
                      disabled={formLoader}
                      className={cn([
                        "text-white  w-full flex items-center justify-center py-2 rounded-lg font-bold duration-75 border-main bg-main text-base",
                        formLoader
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer ",
                      ])}
                    >
                      {t("global_save")}
                    </button>
                  </form>
                </div>
              </div>

              <div className="md:w-1/2 p-4 ">
                <div className="rounded-xl  shadow-2xl bg-white w-full h-full p-6">
                  <div className="rounded-xl h-full">
                    <h3 className="font-bold text-xl text-main mb-4 ">
                      {t("account_notification_title")}
                    </h3>
                    <div className=" w-full relative h-full max-w-lg  block ">
                      {/*<AccountCard*/}
                      {/*  time="3 dk önce"*/}
                      {/*  text="Yaklaşan Randevu"*/}
                      {/*  fullTime="22 Aralık 2021 Tarihli, Saat: 14:30 randevu zamanına 5 gün kaldı."*/}
                      {/*/>*/}
                      {/*<AccountCard*/}
                      {/*  time="3 dk önce"*/}
                      {/*  text="Yaklaşan Randevu"*/}
                      {/*  fullTime="22 Aralık 2021 Tarihli, Saat: 14:30 randevu zamanına 5 gün kaldı."*/}
                      {/*/>*/}

                      {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                          <div className="w-full" key={index}>
                            <AccountCard
                              text={notification.text}
                              fullTime={notification.fullTime}
                            />
                          </div>
                        ))
                      ) : (
                        <div className=" w-full block">
                          <div className="  w-full h-24 flex mb-2 ">
                            <div className="bg-textGrey   w-2 h-full " />
                            <h3 className="text-textGrey text-2xl w-full  font-semibold mb-1 flex items-center justify-center  ">
                              {t("account_no_notification")}
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PrivateRoute>
    </Layout>
  );
}
