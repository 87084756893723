import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaCaretRight,
  FaPhone,
  FaPaperPlane,
  FaMapPin,
} from "react-icons/fa";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const [infos, setInfos] = useState({});

  const getInfos = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "contact-infos/1")
      .then((res) => {
        if (res.data && res.data.status) {
          setInfos(res.data.data);
        }
      })
      .catch((err) => {
        setInfos({});
        console.log(err);
      });
  };

  useEffect(() => getInfos(), []);

  return (
    <div className="footer">
      <footer className="md:container mx-auto px-5  md:flex flex-row py-8 justify-between  ">
        <div className="text-white md:w-1/3 mb-8">
          <div className="mb-10">
            <Link to="/ " className="flex items-center ">
              <img
                src="/assets/images/logo.svg"
                alt=""
                className="h-10  pr-2 bg-white rounded-xl "
              />
              <span className="font-semibold text-white md:text-lg text-sm"></span>
            </Link>
          </div>
          {infos && infos.slogan && (
            <div className="mb-8">
              <p className="text-sm">{infos.slogan}</p>
            </div>
          )}

          <div>
            <div className="flex ">
              {infos && infos.facebook_url && (
                <a
                  href={infos.facebook_url}
                  target="_blank"
                  className="bg-white  flex items-center  p-2 rounded-xl mr-5"
                >
                  <FaFacebookF className=" text-main w-5 h-5 " />
                </a>
              )}

              {infos && infos.twitter_url && (
                <a
                  href={infos.twitter_url}
                  target="_blank"
                  className="bg-white  flex items-center  p-2 rounded-xl mr-5"
                >
                  <FaTwitter className=" text-main w-5 h-5 " />
                </a>
              )}

              {infos && infos.instagram_url && (
                <a
                  href={infos.instagram_url}
                  target="_blank"
                  className="bg-white  flex items-center  p-2 rounded-xl mr-5"
                >
                  <FaInstagram className=" text-main w-5 h-5 " />
                </a>
              )}

              {infos && infos.linkledin_url && (
                <a
                  href={infos.linkledin_url}
                  target="_blank"
                  className="bg-white  flex items-center  p-2 rounded-xl mr-5"
                >
                  <FaLinkedinIn className=" text-main w-5 h-5 " />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="menu text-white mb-8">
          <div>
            <p className="text-primary font-bold ml-5 mb-5">
              {t("footer_quick_menu")}
            </p>
            <div className="menu">
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary mr-1 " />
                <Link to="/">{t("global_header_home")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary  mr-1" />
                <Link to="/hakkimizda">{t("global_header_about")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary mr-1" />
                <Link to="/ekibimiz">{t("global_header_psychologists")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary mr-1" />
                <Link to="/hizmetlerimiz">{t("global_header_services")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary mr-1" />
                <Link to="/blog">{t("global_header_blog")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary mr-1" />
                <Link to="/iletisim">{t("global_header_contact")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary mr-1" />
                <Link to="/kvkk">{t("footer_kvkk")}</Link>
              </div>
              <div className="flex items-center mb-1 hover:text-primary duration-75">
                <FaCaretRight className="text-primary" />
                <Link to="/cerez-politikasi">{t("footer_cookies")}</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="menu">
          <p className="text-primary font-bold ml-5 mb-5">
            {t("footer_contact")}
          </p>

          {infos && infos.phone_1 && (
            <div className="flex items-center mb-2 ">
              <FaPhone className="text-primary mr-2 " />
              <a href={`tel:${infos.phone_1}`} className="text-white hover:underline">
                {infos.phone_1}
              </a>
            </div>
          )}

          {infos && infos.phone_2 && (
            <div className="flex items-center mb-2 ">
              <FaPhone className="text-primary mr-2 " />
              <a href={`tel:${infos.phone_2}`} className="text-white hover:underline">
                {infos.phone_2}
              </a>
            </div>
          )}

          <div className="flex items-center mb-2">
            <FaPaperPlane className="text-primary mr-2 " />
            <span className="text-white"><a href="mailto:info@ndkdanismanlik.com">info@ndkdanismanlik.com</a></span>
          </div>

          {infos && infos.address && (
            <div className="flex items-center mb-2">
              <FaMapPin className="text-primary mr-2 " />
              <span className="text-white">{infos && infos.address}</span>
            </div>
          )}
        </div>
      </footer>
    </div>
  );
}

export default Footer;
