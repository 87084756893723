import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { ImSpinner7 } from "react-icons/im";
import { getMyIP } from "../../utils";

import Creditcard from "../elements/credit-card";
import { toast } from "react-toastify";

const CheckActions = ({ checkPayment, children }) => {
  const authHeader = useAuthHeader();
  const userDatas = useAuthUser();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loader, setLoader] = useState(false);
  const [price, setPrice] = useState(null);
  const [priceName, setPriceName] = useState("");
  const [priceId, setPriceId] = useState(null);

  const getActions = async () => {
    if (checkPayment) {
      setLoader(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "clients/get-actions",
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (response && response.data.status) {
        const data = response.data.data;
        checkPayment && setPaymentStatus(data.payment_status);
      }

      const paymentResponse = await axios.get(
        process.env.REACT_APP_API_URL + "chose-date/client-choose-date",
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      const paymentData = paymentResponse.data.data;
      setPrice(paymentData.price);
      setPriceName(paymentData.price_name);
      setPriceId(paymentData.price_id);
      setLoader(false);
    }
  };

  useEffect(() => {
    getActions();
  }, []);

  const paymentSubmit = async (
    number,
    expiryMonth,
    expiryYear,
    cvv,
    firstName,
    lastName,
    priceId
  ) => {
    try {
      const cardNumber = number.trim().replace(" ", "");
      expiryMonth = expiryMonth.trim();
      expiryYear = expiryYear.trim();
      cvv = cvv.trim();
      let ip = await getMyIP();

      const datas = {
        amount: price,
        cardNumber: cardNumber,
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
        cvv: cvv,
        firstName,
        lastName,
        priceId,
        customentIp: ip,
      };


      const paymentResponse = await axios.post(
        process.env.REACT_APP_API_URL + "payment",
        datas,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (paymentResponse && paymentResponse.data.status) {
        const paymentData = paymentResponse.data.data;
        if (paymentData.status === "WAITING") {
          if (paymentData.only3d && paymentData.form3d) {
            window.location.href = paymentData.form3d;
          }
        } else {
          toast.error(paymentData.message);
        }
      } else {
        toast.error(paymentResponse.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //(
  //             <>
  //               <div className="md:container md:mx-auto px-5">
  //                 <div className=" mx-auto border-2 md:px-8 px-2 py-4 my-6 rounded-2xl shadow-2xl ">
  //                   <iframe
  //                     src={form3dURL}
  //                     frameBorder={0}
  //                     width="100%"
  //                     height={750}
  //                   />
  //                 </div>
  //               </div>
  //             </>
  //           )

  if (loader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-full max-w-xs">
          <div className="flex flex-col items-center">
            <div className="flex justify-center mt-12">
              <ImSpinner7 className="w-16 h-16 text-gray-600" />
            </div>
            <p className="mt-4 text-gray-600 text-center text-sm">Loading...</p>
          </div>
        </div>
      </div>
    );
  } else {
    if (paymentStatus) {
      return (
        <>
          <div className="md:container md:mx-auto px-5">
            <div className=" mx-auto border-2 md:px-8 px-2 py-4 my-6 rounded-2xl shadow-2xl ">
              <h3 className="text-main md:text-2xl   mb-2  mb-2 ">
                Merhaba <b>{userDatas().name_surname}</b>. Randevularınızı
                yönetebilmeniz için ödeme yapmanız gerekmektedir.
              </h3>

              <Creditcard
                header={priceName}
                price={price}
                priceId={priceId}
                paymentSubmit={paymentSubmit}
              />
            </div>
          </div>
        </>
      );
    } else {
      return children;
    }
  }
};

export default CheckActions;
