import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import EyeButton from "../components/base/eye-button";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import PrivateRoute from "../auth/PrivateRoute";

export default function PasswordProgress() {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [showPasswor1, setShowPassword1] = useState(false);
  const [showPasswor2, setShowPassword2] = useState(false);
  const [showPasswor3, setShowPassword3] = useState(false);
  const [chageStart, setChangeStart] = useState(false);
  const [rules, setRules] = useState({
    upper: false,
    number: false,
    symbol: false,
    length: false,
  });

  useEffect(() => {
    if (password) {
      setRules({
        upper: /[A-Z]/.test(password),
        number: /[0-9]/.test(password),
        symbol: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password),
        length: password.length >= 8,
      });
      setChangeStart(true);
    }
  }, [password]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(e);
  };

  return (
    <Layout type={true}>
      <PrivateRoute>
        <div className="bg-gray-200 w-full md:py-20 py-10 px-5">
          <div className="p-5 md:w-1/3 w-full bg-white shadow-2xl rounded-2xl  mx-auto">
            <h3 className="text-main font-bold text-lg mb-2">
              {t("password_header_title")}
            </h3>
            <form onSubmit={handleSubmit}>
              <div className=" flex flex-col flex-wrap -mx-3 mb-4 px-3 rounded-xl">
                <label
                  className="block  tracking-wide text-textGrey text-base font-semibold  mb-2"
                  htmlFor="grid-first-name"
                >
                  {t("password_form_old_password")}
                  <span className="text-red-600 text-lg">*</span>
                </label>
                <div className="relative">
                  <input
                    className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary"
                    id="grid-first-name"
                    type={showPasswor1 ? "text" : "password"}
                    placeholder={t("password_form_old_password")}
                    name="oldPassword"
                  />

                  <EyeButton
                    show={showPasswor1}
                    onClick={() => setShowPassword1(!showPasswor1)}
                  />
                </div>
              </div>
              <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
                <label
                  className="block  tracking-wide text-textGrey text-base font-semibold  mb-2"
                  htmlFor="grid-first-name"
                >
                  {t("password_form_new_password")}
                  <span className="text-red-600 text-lg">*</span>
                </label>

                <div className="relative">
                  <input
                    className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3  leading-tight focus:outline-none focus:border-primary"
                    id="grid-first-name"
                    type={showPasswor2 ? "text" : "password"}
                    placeholder={t("password_form_new_password")}
                    name="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <EyeButton
                    show={showPasswor2}
                    onClick={() => setShowPassword2(!showPasswor2)}
                  />
                </div>
              </div>
              <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
                <label
                  className="block  tracking-wide text-textGrey text-base font-semibold  mb-2"
                  htmlFor="grid-first-name"
                >
                  {t("password_form_confirm_password")}
                  <span className="text-red-600 text-lg">*</span>
                </label>

                <div className="relative">
                  <input
                    className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary"
                    id="grid-first-name"
                    type={showPasswor3 ? "text" : "password"}
                    placeholder={t("password_form_confirm_password")}
                    name="confirmPassword"
                  />

                  <EyeButton
                    show={showPasswor3}
                    onClick={() => setShowPassword3(!showPasswor3)}
                  />
                </div>
              </div>
              <div className="mx-3 mb-4  ">
                <p className="-mx-3 text-main font-semibold mb-2">
                  {t("password_careful")}
                </p>
                <ul className="list-disc px-3 mx-3 text-main font-semibold ">
                  <li
                    className={
                      chageStart
                        ? rules.length
                          ? "text-primary"
                          : "text-red-500"
                        : "text-main"
                    }
                  >
                    {t("password_text_1")}
                  </li>
                  <li
                    className={
                      chageStart
                        ? rules.upper
                          ? "text-primary"
                          : "text-red-500"
                        : "text-main"
                    }
                  >
                    {t("password_text_2")}
                  </li>
                  <li
                    className={
                      chageStart
                        ? rules.number
                          ? "text-primary"
                          : "text-red-500"
                        : "text-main"
                    }
                  >
                    {t("password_text_3")}
                  </li>
                  <li
                    className={
                      chageStart
                        ? rules.symbol
                          ? "text-primary"
                          : "text-red-500"
                        : "text-main"
                    }
                  >
                    {t("password_text_4")}
                  </li>
                </ul>
              </div>
              <button
                disabled={
                  !(rules.symbol && rules.number && rules.upper && rules.length)
                }
                className={cn([
                  "text-white w-full flex items-center justify-center py-2 rounded-lg font-bold duration-75 border-main cursor-pointer text-base",
                  rules.symbol && rules.number && rules.upper && rules.length
                    ? "bg-primary"
                    : "bg-blue-300",
                ])}
              >
                {t("global_save")}
              </button>
            </form>
          </div>
        </div>
      </PrivateRoute>
    </Layout>
  );
}
