import React from "react";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
function EyeButton({ show = false, onClick }) {
  
  return (
    <button
      className=" p-1 absolute top-0 right-0 flex items-center px-2   h-5/6"
      type="button"
      onClick={onClick}
    >
      {show ? (
        <FaRegEyeSlash className="text-gray-600 text-lg" />
      ) : (
        <FaRegEye className="text-gray-600 text-lg" />
      )}
    </button>
  );
}

export default EyeButton;
