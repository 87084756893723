import React, { useEffect, useState } from "react";
import Layout from "../components/partials/layout";
import InfoCard from "../components/elements/info-card";
import AboutUsSection from "../components/elements/about-us-section";
import Button from "../components/base/button";
import Steps from "../components/elements/steps";
import BlogItem from "../components/elements/blog-item";

import { useTranslation } from "react-i18next";
import axios from "axios";
import i18n from "i18next";
import moment from "moment";

function HomeView() {
  const { t } = useTranslation();
  const [blogs, setBlogs] = useState([]);
  const activeLanguage = i18n.language;
  moment.locale(activeLanguage);
  const getDatas = async () => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + "blogs");
      if (result && result.data) {
        const { status, message, data } = result.data;
        if (status) {
          const blogDatas = data.filter((x) => x.lang_code === activeLanguage);
          setBlogs(blogDatas);
        } else {
          setBlogs([]);
        }
      }
    } catch (error) {
      console.log(error);
      setBlogs([]);
    }
  };

  useEffect(() => {
    getDatas();
  }, [activeLanguage]);

  return (
    <Layout pageTitle={t("home_header_little_title")}>
      {/* Home Section start */}
      <div className="home-image  ">
        <div className="md:container mx-auto  md:py-32 pt-28  pl-5 text-white">
          <div className="text-xs pb-2 md:text-sm font-bold ">
            <h1
              dangerouslySetInnerHTML={{
                __html: t("home_header_little_title"),
              }}
            />
          </div>

          <div className="md:text-5xl text-2xl font-bold pb-20 ">
            <h2
              dangerouslySetInnerHTML={{
                __html: t("home_header_main_title_1"),
              }}
            />
          </div>

          <div className="flex">
            <div className="flex pr-8">
              <Button href="/randevu-al" text={t("global_make_appointment")} />
            </div>
            <div className="flex ">
              <Button
                text={t("global_header_about")}
                href="/hakkimizda"
                type="outline-white"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative   ">
        <div className="flex md:container     md:flex-row  flex-col md:absolute md:-top-16 left-0 right-0 mx-auto">
          <InfoCard
            title={t("home_info_card_title_1")}
            text={t("home_info_card_text_1")}
            color="bg-purple text-white"
          />
          <InfoCard
            title={t("home_info_card_title_2")}
            text={t("home_info_card_text_2")}
            color="bg-primary text-white"
          />
          <InfoCard
            title={t("home_info_card_title_3")}
            text={t("home_info_card_text_3")}
            color="bg-white text-main"
          />
        </div>
      </div>
      {/* Home Section end */}

      <div className="md:mt-24 md:mb-24">
        <AboutUsSection />
      </div>

      <div className="bg-main mb-14">
        <div className=" md:container  md:mx-auto  mx-6 bg-main text-white ">
          <div className="mb-6 md:px-16 md:text-center ">
            <h2 className="md:text-4xl text-center text-2xl font-bold  pt-9 pb-5">
              {t("home_support_title")}
            </h2>
            <p className="pb-12 text-current"></p>
          </div>
          <div className="md:flex md:justify-between   ">
            <Steps
              number="1"
              title={t("home_support_steps_1_title")}
              text={t("home_support_steps_1_text")}
            />
            <Steps
              number="2"
              title={t("home_support_steps_2_title")}
              text={t("home_support_steps_2_text")}
            />
            <Steps
              number="3"
              title={t("home_support_steps_3_title")}
              text={t("home_support_steps_3_text")}
            />
          </div>
        </div>
      </div>

      <div className="md:container md:mx-auto px-5 ">
        <div className="text-main text-center">
          <h2 className=" text-2xl md:text-4xl font-bold pb-2">
            {t("home_blog_title")}
          </h2>
          <p className="text-lg mb-16"></p>
        </div>

        <div className="md:flex  mb-10 md:flex-wrap">
          {blogs
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort blogs by created_at in descending order
            .slice(0, 8) // Take the last 6 blogs
            .map((blog, index) => {
              const small = process.env.REACT_APP_API_URL + blog.small;

              return (
                <BlogItem
                  key={index}
                  image={small}
                  title={blog.title}
                  date={moment(blog.created_at).format("DD MMM")}
                  description={blog.description}
                  href={"/blogs/" + blog.slug + "/" + blog.id}
                />
              );
            })}
        </div>
      </div>
    </Layout>
  );
}

export default HomeView;
